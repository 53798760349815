<template>
	<div class="container">
		<div style="height: 11vh; padding: 3vh 0;">
			<LogoImg/>
		</div>
		<div class="row"  v-if="loading">
			<div class="col-12 text-center">
				<q-spinner-ios
					color="green"
					size="6em"
					:thickness="9"
				></q-spinner-ios>
			</div>
		</div>
		<div v-else>
			<div class="row text-center">
				<!-- LEFT WHITE COLUMN -->
				<div class="col"></div>
				<!-- CENTER COLUMN -->
				<div class="col-xs-10 col-sm-8 col-md-6 col-lg-6 col-xl-6 text-center">
					<h2>Le offerte a te riservate:</h2>
					<span v-if="available_promos==1">C'è <b>una</b> offerta per te </span>
					<span v-else>Ci sono <b>{{ available_promos }}</b> offerte che ti aspettano di cui <b>{{ onlyPremium }} premium</b></span>

					<div v-if="available_promos==0">
						<div class="separator-blank q-py-lg"></div>
						<div class="infogroup-dark">
							<h3>Al momento non ci sono offerte attive.</h3>
							<h3>Ti preghiamo di provare più avanti.</h3>
						</div>
						<div class="separator-blank q-py-lg"></div>
					</div>

					<div v-if="logged">
						<!-- PROMO FILTER -->
						<div class="row">
							<div class="col text-right">
									Filtra le offerte
								<q-toggle
									color="green"
									v-model="filter"
									icon="search"
								></q-toggle>
							</div>                 
						</div>
						<div class='infogroup-dark container text-left' v-if="filter">
							<div>
								<b>Filtra per categoria</b> <p/>
								<span class="q-ma-sm" v-for="(cat, idx) in categories" :key="idx">
									<q-toggle color="green" 
										:label=cat 
										v-model="cat_selection" 
										:val=cat
										@update:model-value="updateFilter"
									></q-toggle>
								</span>
								<br/>
								<b>Filtra per tag</b> <p/>
								<span class="q-ma-sm" v-for="(tag, idx) in presented_tags" :key="idx">
									<q-toggle color="green" 
										:label=tag 
										v-model="tag_selection" 
										:val=tag
										@update:model-value="updateFilter"
									></q-toggle>
								</span>
							</div>
						</div>

						<!-- PROMOS -->
						<div :key="refresh">
							<div  v-for="(promo, idx) in presented_promos" :key="idx" >
								<!--DISPLAY PROMO -->
								<PromoCard 
									v-bind:promo="promo"
									v-bind:hascard="hascard"
								/>
							</div>
						</div>

						<!-- PAGINATION -->
						<div class="separator-blank q-my-lg"></div>
						<div class="row justify-center full-width text-center">
							<q-pagination
								v-model="current"
								:max="maxpage"
								color="green"
								size="1.3em"
								direction-links
								@update:model-value="updatePagination"
							></q-pagination>
						</div>

					</div>
					<div v-else>
						<div class="infogroup-dark">
							<h3>Attenzione!</h3>
							Non puoi visualizzare le offerte perchè queste sono riservate agli utenti FaunaLife.<p/>

							Se sei un utente FaunaLife 
							<a v-bind:href="app_login">accedi al tuo profilo</a>
							sulla app per tornare a questa pagina<p/>
							Se NON sei un utente FaunaLife 
							<a v-bind:href="app_login">registrati</a>!
						</div>
					</div>

				</div>

				<!-- RIGHT WHITE COLUMN -->
				<div class="col bg-dogGirl"></div>
			</div>
		</div>


		<!-- Expired Card Dialog -->
		<q-dialog 
			v-model="card_expired" 
			persistent
			transition-show = "slide-down"
			transition-hide = "slide-up"
			transition-duration = "800"
		>
			<q-card>
				<q-card-section>
					<div class="row items-center">
						<div class="col-12 text-center"><h3>Attenzione !</h3></div>
					</div>
					<div class="row items-center">
						<div class="col-12 text-center"><q-icon name="pets" color="green" size="100px"/></div>
					</div>

					<div class="row items-center">
						<div class="col-12 text-center">
							<h3>La tua MyPetCard è scaduta!</h3>
						</div>
					</div>
					<div class="separator-blank q-my-lg"></div>
					<div class="row items-center">
						<div class="col-12 text-center">
							Rinnova la tua MyPetCard per il tuo pet così da non perderti gli
							<b>sconti premium</b>
							
						</div>
					</div>
				</q-card-section>

				<q-card-actions align="center">
					<q-btn 
						flat 
						icon="close" 
						label="annulla" 
						color="red" 
						v-close-popup 
						class="q-mx-xl"
						></q-btn>
						<q-btn 
						icon="check" 
						label="rinnova"
						color="green"
						v-close-popup
						class="q-mx-xl"
						@click="renew_card"
					></q-btn>
				</q-card-actions>
			</q-card>
		</q-dialog>

	</div>
</template>



<script>
import * as config from '../common/config'
import * as tools from '../common/tools'

import LogoImg from "../components/Logo"
import PromoCard from '../components/PromoCard'

export default {
    name: 'PromoView',
	components: {
		LogoImg,
		PromoCard
	},

	props : {
		token 		: {
			type		: String,	
			required	: false,
			default		: null
		},
	},

	data () {
		return {
			user			: {},
			logged			: false,
			hascard			: false,
			card_expired	: false,

			loading			: true,
			promos			: [],
			presented_promos: [],
			selected_promos : [],
			categories		: [],
			cat_selection 	: [],
			tags 			: [],
			presented_tags	: [],
			tag_selection 	: [],
			filter			: false,
			available_promos: 0,
			onlyPremium 	: 0,
			refresh			: 0,

			// Pagination
			// minpage			: 1,
			maxpage			: 1,
			promoPerPage	: config.Pagination_perPage,
			app_login		: config.AppBaseUrl+'/login'
		}
	},

	computed: {
	},

    methods: {
		checkUser : async function () {
			// Possible ways to access the page :
			// previous authorization (JWT)
			// 1 time token in query string
			// fluid as prop
			
			let checked = false
			try {
				
				// Method 0 : Check (server side) if has a valid JWT token
				if (checked === false ) {
					let jwt = await tools.apiShopIsLogged()
					if (jwt.ok) {
						this.user = await tools.apiGetUser(jwt.user_fluid)
						checked = true
						this.user = {...this.user, ...jwt}
						tools.storeUser(this.user)
					}
				}
				
				// Method 1 : Check if getting here via OneTimeToken
				if (checked === false ) {
					let query = this.$route.query
					if (query && query.ott) {
						let token = query.ott
						let ott = await tools.apiShopCheckOTToken(token)
						if (ott.ok) {
							this.user = await tools.apiGetUser(ott.user_fluid)
							checked = true
							this.user = {...this.user, ...ott}
							tools.storeUser(this.user)
						}
					}
				}
	
				// // Method 2 : local storage (suppose we logged in and have a JWT token)
				// if (checked === false ) {
				// 	this.user = tools.getUser()
				// 	if (this.user && (this.user.user || this.user.fluid)) {
				// 		checked = true
				// 	}
				// }

				// // Method 3 : Check if prop is a fluid
				// if (checked === false && tools.validAddress(this.token)) {
				// 	// Supplied token is a fluid, use that
				// 	this.user = await tools.apiGetUser(this.token)
				// 	checked = true
				// 	delete this.user.ok
				// 	tools.storeUser(this.user)
				// }

			} catch (e) {
				console.error(e.message)
			}

			// Chech if user exists and has valid card
			if (checked) {
				delete this.user.ok
				this.logged = true
				let hascard = await tools.apiUserHasCard(this.user.user)
				if (!hascard.exists) {
					return false
				}
				this.hascard = hascard.hascard
				if (!this.hascard) {
					if (hascard.cards && Array.isArray(hascard.cards) && hascard.cards.length > 0) {
						// User has/had card(s) but they expired
						this.card_expired = true
					}
				}
			}
			return checked
		},


		// Checks if a fluid is supplied, if it is correct fluid and gets the promos
		getPromos : async function() {
			if (!this.logged) {
				return
			}

			// Get the available promos
			this.promos = await tools.apiShopGetPromos(this.user.user)
			
			if (!this.promos.ok) {
				this.promos = []
			}
			delete this.promos.ok
			
			// Gather some info
			for (let promo of this.promos) {
				// If banner is missing use logo
				if (!promo.banner_url || promo.banner_url == '') {
					promo.banner_url = promo.logo_url
				}
				// Update global arrays
				this.categories = this.categories.concat(promo.category)
				this.tags = this.tags.concat(promo.promo_tags)
			}

			// Initialize filter data etc
			this.categories = [...new Set(this.categories)]
			this.tags = [...new Set(this.tags)]
			this.presented_tags = [...this.tags]
			// Assume no filter, all toggles selected
			this.cat_selection = this.categories
			this.tag_selection = this.tags
			this.selected_promos = [...this.promos]
			this.updatePagination(1)
		},

		getPromoStats : async function () {
			let totals = await tools.apiShopGetPromoStats()
			this.onlyPremium = totals.premium
			this.available_promos = totals.count
		},

		updateFilter : function() {
			// Filter presented tags
			this.presented_tags = this.promos.filter( x => {
				return this.cat_selection.includes(x.category)
			}).reduce( (a,c) => {
				return a.concat(c.promo_tags)
			}, [])


			// Filter presented promos
			this.selected_promos = this.promos.filter( x=> {
				// get just the promos whose category is in cat_selection
				return this.cat_selection.includes(x.category)
			}).filter( x => {
				// Get just the promos that have at least one tag in tag_selection
				let intersection = this.tag_selection.filter( y => {
					return x.promo_tags.includes(y)
				})
				return intersection.length > 0
			})

			// Re-render page
			console.error("SP:", this.selected_promos)
			this.updatePagination(1)
		},

		updatePagination : function(page) {
			// Extract part of promos
			let promoA = this.promoPerPage * (page - 1)  // 0-7, 8-15, 16-23
			let promoZ = (this.promoPerPage * page) -1
			promoZ = Math.min(promoZ, this.selected_promos.length)


			console.error("AZ:", promoA, promoZ)
			this.presented_promos = this.selected_promos.slice(promoA,promoZ)
			console.error("PP:", this.presented_promos)
			this.maxpage = Math.ceil(this.selected_promos.length / this.promoPerPage)
			// Force page redraw
			this.refresh += 1
		},

		renew_card : function() {
			console.error("AAA")
			let url = '/buy'
			let user = tools.getUser()
			if (user) {
				url = url+'?email='+user.user_email
			}
			this.$router.push(url)
		}
    },

	// Lifecycle Hooks
	async mounted() {
		window.scrollTo(0,0)
		this.getPromoStats()
		await this.checkUser()
		this.getPromos()
		this.loading = false
	},
}
</script>

<style scoped>

</style>