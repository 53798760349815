<template>
    <div class="container bg-dark q-py-xs q-px-md vertical-middle">
        <div class="row">
            <div class="text-left col-xs-2 col-sm-2 col-md-3 col-lg-3 col-xl-3">
                <a href="https://fauna.life" target="_blank" >
                    <img width="40" height="40" role="img" src="../assets/ithink.png" class="vertical-middle" />
                    <q-tooltip class="text-h6">Sito</q-tooltip>
                </a>
            </div>

            <div class="text-center self-center col-xs-7  col-sm-7  col-md-6  col-lg-6 col-xl-6 q-pa-l">
                <div class="container">
                    <div class="row justify-center">
                        <!-- <div class="col col-2 q-ma-m" v-if="!is_logged">
                            <router-link to="/login"  class="text-white">
                                <span class="material-icons" style="font-size: 30px">home</span>
                                <q-tooltip class="text-h6">Vai alla<br>Login</q-tooltip>
                            </router-link>
                        </div> -->
                        <div class="col col-2 q-ma-m">
                            <router-link to="/buy"  class="text-white">
                                <span class="material-icons" style="font-size: 30px">credit_card</span>
                                <q-tooltip class="text-h6">MyPetCard</q-tooltip>
                            </router-link>
                        </div>
                        <div class="col col-2 q-ma-m">
                            <router-link to="/promo" class="text-white">
                                <span class="material-icons" style="font-size: 30px">shopping_cart</span>
                                <q-tooltip class="text-h6">Le offerte a te riservate</q-tooltip>
                            </router-link>
                        </div>
                        <div class="col col-2 q-ma-m">
                            <router-link to="/biz" class="text-white">
                                <span class="material-icons" style="font-size: 30px">storefront</span>
                                <q-tooltip class="text-h6">Le aziende convenzionate</q-tooltip>
                            </router-link>
                        </div>
                        <div class="col col-2 q-ma-m">
                            <a href="https://www.fauna.life/guide-e-howto/" class="text-white" target="_faunalife">
                            <span class="material-icons" style="font-size: 30px">contact_support</span>
                            <q-tooltip class="text-h6">Supporto e FAQ</q-tooltip>
                            </a>
                        </div>

                        <!-- <div class="col col-2 q-ma-m">
                            <router-link to="/sos" class="text-red">
                                <span class="material-icons-outlined" style="font-size: 22px">sos</span>
                                <q-tooltip class="text-h6">Segnala<br>un'emergenza</q-tooltip>
                            </router-link>
                        </div> -->
                    </div>
                </div>
            </div>
            

            <!-- 
            <div class="text-right self-center col-xs-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 q-px-xs" v-if="is_logged">
                <q-btn-dropdown 
                    split
                    color="white"
                    text-color="green"
                    icon="person"
                    size="15px"
                    dense=true
                    class="q-pa-xs"
                    v-on:click="this.$router.push({path : '/user/'+this.user_fluid})"
                >
                    <q-list>
                        <q-item clickable v-close-popup v-on:click="this.$router.push({path : '/user/'+this.user_fluid})">
                            <q-item-section avatar>
                                <q-avatar icon="person" color="green" text-color="white"></q-avatar>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label>Profilo</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item clickable v-close-popup v-on:click="this.share_profile()">
                            <q-item-section avatar>
                                <q-avatar icon="share" color="green" text-color="white"></q-avatar>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label>Condividi</q-item-label>
                            </q-item-section>
                        </q-item>
                        <q-item clickable v-close-popup v-on:click="goSupport()">
                            <q-item-section avatar>
                                <q-avatar icon="contact_support" color="green" text-color="white"></q-avatar>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label>Supporto</q-item-label>
                            </q-item-section>
                        </q-item> 
                        <q-separator spaced></q-separator>
                        <q-item clickable v-close-popup v-on:click="goLogOut()">
                            <q-item-section avatar>
                                <q-avatar icon="logout" color="green" text-color="white"></q-avatar>
                            </q-item-section>
                            <q-item-section>
                                <q-item-label>Logout</q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                </q-btn-dropdown>
            </div> 
            -->


        </div>
    </div>

    <!-- <LogoImg v-if="showLogo" />  -->


</template>



<script>
    // import * as tools from "../common/tools"
    // import * as config from "../common/config"
    // import LogoImg from "./Logo"

    export default {
    name : 'HeaderBar',
    components: {
        // LogoImg
    },
 
    props : {            // values passed by caller Commponent via props
        page : String,  // short version
        showLogo: {
            type: Boolean,
            default: true,
        },
    },
 
    data () {            // State(s) function
        return {
        } 
    },
 
    methods: {           // Component functions
    },

    computed : {
    },
 
    watch: {             // Stuff that happend when a variable changes
    },
 
    // Lifecycle Hooks
    mounted() {
    },

    updated() {
    },
 
    created() {
    },
  }
</script>


<style>
</style>