<template class="fixed-full">
    <div class="row">
        <!-- <div class="col-xs-0 col-sm-0 col-md-1 col-lg-2 col-xl-3 petbg">
            &nbsp;
        </div> -->
        <!-- <div class="col-xs-12 col-sm-12 col-md-10 col-lg-8 col-xl-6"> -->
        <div class="col-12">
            <HeaderBar />
            <div class="container-md">
                <slot />
            </div>
        </div>
        <!-- <div class="col-xs-0 col-sm-0 col-md-1 col-lg-2 col-xl-3 petbg">
            &nbsp;
        </div> -->
    </div>
</template>

<script>
    import HeaderBar from '../Header'
    export default {
        name: 'DefaultLayout',
        components: {
            HeaderBar,
        },
    }
</script>

<style>
</style>