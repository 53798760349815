<template>
	<div class="row" style="min-height: 94vh;">
		
		<div class="col-xs-12 col-sm-12 col-md-6 col-lg-5 col-xl-5 bg-Cats" style="min-height: 200px;">
			<div>
				<div style="height: 11vh; padding: 3vh 0;">
					<LogoImg/>
				</div>
				<div class="separator-blank q-my-lg"></div>
				<div v-if="validFluid===false">
					<q-img
						width="70%" 
						src="@/assets/SampleCard.png"
						>
					</q-img>
				</div>
			
				<!-- Card Preview  -->
				<div v-else class="scalable-wrapper" style="min-height: 40vh;">
					<div class="card_background card_font">
						Esempio:
						<div class="card_data">
							<p class="datapos_name">
								{{data.pet_name|| '&nbsp; '}}
							</p>
							<p class="datapos_dob">
								{{data.bith_date}}
							</p>
							<p class="datapos_sex">
								{{data.gender}}
							</p>
							<p class="datapos_species">
								{{sel_species}}
							</p>
							<p class="datapos_breed">
								{{short_breed[0] || ' '}}
							</p>
							<p class="datapos_breed2">
								{{short_breed[1] || '&nbsp; '}}
							</p>
							<p class="datapos_owner">
								{{data.owner_name}}
							</p>

							<img class="datapos_qr_code"
								:src="qr_url"
								alt="QRCode"
							/>	

						</div>
						<div class="datapos_id_picture_container">
							<img  class="datapos_id_picture"
									:src="data.profilePicture"
									alt="Avatar"
							/>
						</div>
					</div>
				</div>

				<div class="text-left warning" v-if="validFluid===false">
					<b>Attenzione:</b> <br>per ricevere MyPetCard il tuo pet deve essere dotato di <b>microchip</b>!
				</div>
				<div class="separator-blank q-my-lg"></div>
				<div class="row">
					<div class="col"></div>
					<div class="text-justify col-10">
						Supporta anche tu la creazione di un Registro Animale Internazionale ordinando MyPetCard!<br/>
						MyPetCard è la tessera più fedele al tuo pet con cui potrai:
						<ul>
							<li>segnalare lo smarrimento del tuo pet con il tasto SOS</li>
							<li>costruire l'albero genealogico</li>
							<li>avere tutti i documenti a portata di click</li>
							<li>ricevere tanti sconti e offerte per il tuo pet!</li>
						</ul>
						
						In offerta lancio a <b>19,90 euro</b> invece di 27!

					</div>
					<div class="col"></div>
				</div>
			</div>
		</div>
		
		<!-- Fields form -->
		<div class="col-xs-12 col-sm-12 col-md-6 col-lg-7 col-xl-7 bg-dogGirl" style="min-height: 100%;">
			<div class="row">
				<div class="col-xs-12 col-sm-12 col-md-12 col-lg-10 col-xl-8">
					<div>
						<div>
							<div class="separator-blank q-my-lg"></div>
							<h2>Richiedi MyPetCard</h2>
							<h3 v-if="step<5">Inserisci i dati del tuo pet</h3>
						</div>
						<div class="separator-blank q-my-lg"></div>
						<!-- Spinner Step 0 -->
						<div class="step items-center q-my-md q-mx-xl text-center" v-if="step==0">
							<q-spinner-ios
								color="green"
								size="6em"
								:thickness="9"
							></q-spinner-ios>
						</div>

						<!-- <div class="announcement">
							<h3>Attenzione</h3>
							Causa chiusura estiva dei fornitori, la spedizione delle MyPetCard acquistate
							riprenderà il 4 Settembre.
						</div> -->
	
						<!-- Privacy & Buy-->
						<div class="step items-center text-left  q-my-md q-mx-xl" v-if="step==5"> 
							<div v-if="validFluid===false">
								<h4>Conferma la privacy e procedi all'ordine</h4>
								<div class="separator-blank q-my-lg"></div>
								<q-toggle
									v-model="data.cq_gdpr"
									checked-icon="check"
									color="green"
									unchecked-icon="clear"
								>
									<template v-slot:default>
										Confermi di aver preso visione dell’
										<a href="https://www.fauna.life/informativa-privacy/" target="_blank"><b>informativa sulla privacy</b></a> e <a href="https://www.fauna.life/cookie-policy/" target="_blank"><b>cookie policy</b></a>
										&nbsp;<q-avatar :color='icm_cq_gdpr' text-color="white" icon="pets" size="34px"></q-avatar>
									</template>
								</q-toggle>
								<p>&nbsp;</p>
								Acconsenti ai trattamenti dei dati personali indicati nell’informativa privacy precedentemente fornita,
								relativi a:
								<p>&nbsp;</p>
								<q-toggle
									v-model="data.cq_blockchain"
									checked-icon="check"
									color="green"
									unchecked-icon="clear"
								>
									<template v-slot:default>
										Creazione di un <b>ID Digitale</b> per te e per i tuoi pet sulla piattaforma FaunaLife   
										<q-avatar :color='icm_cq_blockchain' text-color="white" icon="pets" size="34px"></q-avatar>
									</template>                            
								</q-toggle>
								<div class="separator-blank q-my-md"></div>
								<q-toggle
									v-model="data.cq_newsletter"
									checked-icon="check"
									color="green"
									unchecked-icon="clear"
								>
									<template v-slot:default>
										Ricezione di <b>aggiornamenti, offerte e sconti</b> tramite la nostra <b>newsletter</b>&nbsp;
										
										<q-avatar :color='icm_cq_newsletter' text-color="white" icon="pets" size="34px"></q-avatar>
									</template>
								</q-toggle>
								<br>
							</div>
	
							<div class="separator-blank q-my-lg"></div>
	
							<div class="text-center">
								<h4>Procedi all'ordine:</h4>
							</div>
							<div class="separator-blank q-my-lg"></div>

							<div class="col-12 text-center">
								<q-btn
									:color="canOrderColor" 
									text-color="white" 
									rounded
									v-bind:disabled="canOrder==false"
									size='80%'
									stack
									@click="buy_stripe()"
								>
									<q-img class="buy_icon"
										width="280px"
										src="@/assets/paymentsti.png"
									>
									</q-img>
									Carte
								</q-btn><br>
							</div>		
							<div class="separator-blank q-my-lg"></div>								
							<div class="col-12 text-center">
								<q-btn
									:color="canOrderColor" 
									text-color="white" 
									rounded
									v-bind:disabled="canOrder==false"
									size='80%'
									stack
									@click="buy_paypal()"
								>
									<q-img class="buy_icon"
										width="90%" 
										src="@/assets/paypal.png"
									>
									</q-img>
									PayPal
								</q-btn>
							</div>
							<div class="col"></div>

							<div class="separator-blank q-my-lg"></div>
												<!-- Mandatory marker -->
							<div class="col text-left q-pa-md" v-if="canOrder==false">
								<q-avatar color='red' text-color="white" icon="pets" size="34px"></q-avatar> Campi Obbligatori 
							</div>
							<div class="devinfo">
								App Version: {{ version }}
							</div>
						</div>
	
					</div>

				</div>

				<!-- Righ spacing Column -->
				<div class="col-xs-0 col-sm-0 col-md-0 col-lg-2 col-xl-4">
					&nbsp;
				</div>
			</div>
		</div>
	</div>
</template>



<script>
import * as tools from '../common/tools'
import * as config from '../common/config'
import LogoImg from "../components/Logo"
import axios from 'axios'

export default {
	name: 'BuyView',
	components: {
		LogoImg
	},

	props : {
		fluid 		: {
			type		: String,	
			required	: false,
			default		: null
		},
	},

	data () {
		return {
			version : config.AppVersion,

			// Wizard & pagination
			step : 0,
			chip_error : false,
			chip_confirmed : false,
			email_error : false,
			todaystr : '',

			// Collected Data
			data	: {},

			// Image vars
			img_file : null,
			img_hash : null,

			minImgSize : config.AvatarSize,
			avatar_size : config.AvatarSize+'px',
			date_regex : config.Date_RegExp,
			email_regex : config.Email_RegExp,

			// Drop Down lists, selections and values
			species_list : '',
			breed_list :'',
			state_list : '',
			sel_species : '',
			sel_breed : '',
			short_breed : [' ', ' '],
			sel_build : '',
			opt_species : '',
			opt_breed : '',
			opt_breed_filtered : '',

			// Green/red paw colors
			icm_name	: 'red',
			icm_chip	: 'red',
			icm_pic		: 'red',
			icm_species	: 'red',
			icm_breed	: 'red',
			icm_dob		: 'red',
			icm_sex		: 'red',
			icm_owner	: 'red',
			icm_email	: 'red',
		}
	},

	computed : {

		validFluid : function () {
			return tools.validAddress(this.fluid)
		},

		qr_url : function () {
			if (this.data.pet_tag != '') {
				return config.ApiBaseUrl+'/util/qr/'+this.data.pet_tag
			} else {
				return config.ApiBaseUrl+'/util/qr/faunalife'
			}
		},

		canOrder : function () {
			return (this.data.cq_blockchain && this.data.cq_gdpr && this.data.cq_newsletter) || this.fluid
		},

		canOrderColor : function () {
			return (this.canOrder) ? 'green' : 'grey'
		},

		canOrderGreyscale : function () {
			return (this.canOrder) ? 0 : 1
		},

		icm_cq_gdpr : function () {
			return (this.data.cq_gdpr)?'green':'red'
		},

		icm_cq_blockchain : function () {
			return (this.data.cq_blockchain)?'green':'red'
		},

		icm_cq_newsletter : function () {
			return (this.data.cq_newsletter)?'green':'red'
		},
	},

	methods : {

		// Page ---------------------------------------------------------------

		update_short_breed : function () {
			if (this.sel_breed) {
				let short = this.sel_breed.replace(/ \(.*$/, '')
				let line=[' ',' ']
				
				let words = short.split(/[ ,]+/)
				let l = 0
				line[l] = words.shift()
				while (words.length) {
					let w = words.shift()
					if (line[l].length + w.length +1 <= 22) {
						line[l]+=' '+w
					} else {
						l+=1
						line[l] = w
					}
					short = line[0]+"<br>"+line[1]
				}
				this.short_breed = line
			}
		},

		// Import data from main APP
		importData : async function (pet) {
			this.species_list = await tools.apiGet('/util/lookup/species?distinct=true&cols=id,IT as species_name')
			if (!this.species_list.ok) {
				console.error(this.species_list.message)
				return
			}
			this.data.species_id = parseInt(pet.rawcodes.species)
			this.breed_list = await tools.apiGet('/util/lookup/breeds/?distinct=true&species_id='+this.data.species_id+'&cols=breed_id,IT as breed_name,EN,ORIGINAL')
			if (!this.breed_list.ok) {
				console.error(this.breed_list.message)
				return
			}

			// Get data from blockchain
			this.data.pet_name		= pet.rawattributes.name
			if (this.data.pet_name.length > 20) {
				this.data.pet_name = this.data.pet_name.slice(0,20) + '...'
			}
			this.data.pet_tag		= pet.rawattributes.idtag
			this.data.bith_date		= tools.date2EUDate(pet.rawattributes.birth)
			this.data.gender		= pet.rawattributes.gender
			this.data.cq_gdpr		= true
			this.data.cq_blockchain = true
			this.data.cq_newsletter = true
			this.sel_species		= this.species_list.filter(x => {return x.id == parseInt(pet.rawcodes.species)}).map(x=>{return x.species_name})[0]
			this.sel_breed			= this.breed_list.filter(x=>{return x.breed_id == parseInt(pet.rawcodes.breed)}).map(x=>{return x.breed_name})[0]
			this.update_short_breed()

			this.data.owner_name		= '(...il tuo nome qui...)'
			this.data.profilePicture	= pet.profilepicurl[pet.profilepicurl.length-1]
		},


		initialize : async function () {
            let pet = null

            if (tools.validAddress(this.fluid)) {
                pet = await tools.apiGetPet(this.fluid)
				console.error(pet)
				this.importData(pet)
            }
            if (pet!=null){
                this.data.fluid         = this.fluid
                this.data.cq_gdpr		= true
                this.data.cq_blockchain = true
                this.data.cq_newsletter = true
            } else {
                this.data.fluid         = null
                this.data.cq_gdpr		= false
                this.data.cq_blockchain = false
                this.data.cq_newsletter = false
            }

			// Check email if present in querystring
			if (this.$route.query && this.$route.query.email) {
				if ((await tools.apiGetEmailIsValid(this.$route.query.email)) !== true) {
					delete this.$route.query.email	
				} 
			}
			// Go to page and remove the spinner
			this.step = 5
		},

		buy : async function(endpoint) {
            // Start Spinner
            this.step = 0

			/**
			 * Call backend to get a stripe link to pay
			 * Have to handle this manually (and not with the usual 303 redirect) for... reasons
			 */
			let posted = {}
			let r
			try {
				r = await axios.post(config.ApiShopUrl+'/buy/'+endpoint, this.$route.query, {
										withCredentials: true,
										// validateStatus: status => {return true;}    		//TRICK: consider all responses valid...
										validateStatus: status => {return status==status}	// Because Vue complains about unused vars...
									})

				if (r.status != 200) {                                              // ... and handle them manually later
					console.error(JSON.stringify(r,null,4))
					posted.ok = false
					posted.message = r.status+' ' + JSON.stringify(r.data,null,4)
					this.$router.push("/cancel")
				} else {
					posted = r.data
					posted.ok = true
					console.error("Posted: ", posted)
					// Go to stripe, bon voyage
					window.location.replace(posted.payment_url)
				}
			} catch (e) {
				posted.ok = false
				posted.message = e.message
				console.error("Posted: ", posted)
				this.$router.push("/cancel")
			}
		},

		buy_paypal : async function() {
			this.buy('paypal')
		},

		buy_stripe : async function() {
			this.buy('stripe')
		}
	},

	// Lifecycle Hooks
	mounted() {
		window.scrollTo(0,0)
		this.initialize()
	},

}
</script>


<style>
	.bg-Cats, .bg-dogGirl {
		background-repeat: no-repeat;	
		/* filter: blur(5px);
		box-shadow: 0 0 0 100vmax rgba(0, 0, 0, .3); */
	}

	/* Large Screens */
	@media screen and (min-width: 1024px) {
		.bg-dogGirl {
			background-image: url("@/assets/DogGirl.png");
			background-position: right bottom;
			/* background-repeat: no-repeat;
			filter: blur(5px);
			box-shadow: 0 0 0 100vmax rgba(0, 0, 0, .3); */
		}

		.bg-Cats {
			background-image: url("@/assets/Cats.png");
			background-position: left bottom;
			/* background-repeat: no-repeat;
			filter: blur(5px);
			box-shadow: 0 0 0 100vmax rgba(0, 0, 0, .3); */
		}

		.warning {
			margin : 0px 120px;
		}
	}

	/* Small Screens */
	@media screen and (max-width: 1023px) {
		.bg-dogGirl {
			background-image: none;
		}

		.bg-Cats {
			background-image: none;
		}

		.warning {
			margin : 0px 20px 20px;
		}
	}

	.scalable-wrapper {
		padding: 5px;
		/* resize: both; */
		/* position: fixed; */
		position: relative;
		/* background: #666; */
		height: 300px;
		z-index: 10; 
		/* position: fixed;  */
		/* position: sticky; 
		position: -webkit-sticky; */
		/* right: 0;  */
		top: 0px;
	}

	.card_background {
		background-image: url("@/assets/Front_BLANK_Fake3.png");
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;

		width: 400px;
		height: 300px;
		padding: 10px;
		text-align: left;
		position: relative;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		transform-origin: center center;
	}

	.card_font {
		outline: none!important;
		white-space: nowrap!important;
		word-break: keep-all;
		text-transform: capitalize;
		z-index: 1;
		line-height: 12px!important;;

		font-family: "Arial Black", Gadget, sans-serif;
		font-size: 15px;
		letter-spacing: 1px;
		word-spacing: 1px;
		color: #000000;
		font-weight: 700;
		text-decoration: none;
		font-style: normal;
		font-variant: normal;
	}

	.card_data {
		position : absolute;
		left: 85px;
		top: 59px;
	}

	.datapos_name {
		position : absolute;
		left: 10px;
		top: 8px;
	}

	/* 
		Note to self: 
		this part hereunder is some crappy CSS woodoo
		making the image smaller... makes it disappear
		Dunno why
	*/
	.datapos_id_picture_container {
		position : absolute;
		display : block;
		left: 274px;
		top: 45px;
		height: 100px;
		width: 100px; 
	}

	.datapos_id_picture {
		border-radius: 50%;
		height: 100%;
		width: 100%; 
	}

	.datapos_qr_code {
		position: absolute;
		height: 83px;
		width: 83px;
		left : 199px;
		top : 108px;
	}

	.datapos_species {
		position : absolute;
		left: 10px;
		top : 92px;
	}

	.datapos_breed {
		position : absolute;
		left: 10px;
		top : 119px;
	}
	.datapos_breed2 {
		position : absolute;
		left: 10px;
		top : 134px;
	}

	.datapos_dob {
		position : absolute;
		left: 10px;
		top : 38px;
	}

	.datapos_sex {
		position : absolute;
		left: 10px;
		top : 64px;

	}

	.datapos_owner {
		position: absolute;
		left : 10px;
		top : 178px;
	}

	/* 
		those css values apply only to the .avatar_cropper div in this page
		used to give the circle selection to the cropper
	*/
	.avatar_cropper .cropper-view-box {
		box-shadow: 0 0 0 1px #39f;
		border-radius: 50%;
		outline: 0;
	}
	.avatar_cropper .cropper-face {
		background-color:inherit !important;
	}
	.avatar_cropper .cropper-view-box {
		outline:inherit !important;
	}

	/*  
		Color of the buy option icons
	*/
	.buy_icon {
		filter: grayscale(v-bind(canOrderGreyscale));
		-webkit-filter: grayscale(v-bind(canOrderGreyscale));
	}

	/*  
		App Version Watermark
	*/
	div.devinfo {
    font-family: Avenir,Helvetica,Arial,sans-serif;
    float: right;
    padding: 3px;
    font-size: 9px;
    color: var(--fl-whatermark);
    font-style: none;
    line-height: 220px;
	}

	/*
		Announcement styles
	*/
	.announcement {
		border: red;
		border-style: solid;
		border-radius: 25px;
		border-width: 8px;
		color: red;
		width: 100%;
		align-self: center;
		padding: 20px;
		margin: 0px;
	}

	.announcement h3{
		color: red;
	}

</style>