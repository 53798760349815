<template>
  <div class="home">
		<div class="separator-blank q-my-xl"></div>
		<h3>Compra la Card per il tuo Pet</h3>	
		<div class="separator-blank q-my-xl"></div>
		<q-btn
		color="green" 
		text-color="white" 
		rounded
		stack
		size='xxl'
		@click="goShopping()"
		>
			<q-img class="buy_icon"
				width="80px" 
				src="@/assets/credit-card.png"
			>
			</q-img>
			<span class="text-bold text-uppercase">Vai all'acquisto</span>
		</q-btn>
		<div class="separator-blank q-my-xl"></div>
		<div class="devinfo">
			App Version: {{ version }}
		</div>
  </div>
</template>

<script>
import * as config from '../common/config'

export default {
  name: 'HomeView',
  components: {
    
  },
  data () {
    return {
		version : config.AppVersion
    }
  },

  methods : {
    goShopping : async function () {
          this.$router.push("/buy/?"+new URLSearchParams(this.$route.query).toString())
        }  
	},

	// Lifecycle Hooks
	mounted() {
		// Go to Buy by default
		window.scrollTo(0,0)
	},

}
</script>

<style scoped>

div.devinfo {
    font-family: Avenir,Helvetica,Arial,sans-serif;
    float: right;
    padding: 3px;
    font-size: 9px;
    color: var(--fl-whatermark);
    font-style: none;
    line-height: 220px;
}
</style>