import * as config from './config'
import axios from 'axios'
import sha256 from 'js-sha256'

// Check if the provides tring is a valid hex rapresentation
// of n bytes (case insensitive)
// string must start with "0x"
function validBytes(n, str) {
    var r = "^0x[a-f0-9]{"+(2*n).toString()+"}$";
    var regex = new RegExp(r,"i");
    return str.match(regex) !== null;
}

// Check if the provides tring is a valid address (case insensitive)
function validAddress(addr) {
    return validBytes(20, addr);
}

async function apiPost(postbody, endpoint, headers=null) {

    // Get url to call. Is it full or relative to the base api url?
    let endpoint_url = (endpoint.startsWith('http')) ? endpoint : config.api_base_url+endpoint

    let posted = {}
    let r
    try {
        r = await axios.post(endpoint_url, postbody, {
                                withCredentials: true,
                                headers : headers,
                                validateStatus: (status => {return status==status})    //TRICK: consider all responses valid...
                            })
        if (r.status != 200) {                                              // ... and handle them manually later
            console.error(JSON.stringify(r,null,4))
            posted.ok = false
            posted.message = r.status+' ' + JSON.stringify(r.data,null,4)
        } else {
            posted = r.data
            if (r.headers['set-cookie']) {
                posted.cookies = r.headers['set-cookie']
            }
            posted.ok = true
        }
    } catch (e) {
        posted.ok = false
        posted.message = e.message
    }
    return posted
}

async function apiGet(endpoint, full=false) {
    let fetched = {}
    if (full===false){
        endpoint = config.ApiBaseUrl+endpoint
    }

    try {
        let r = await axios.get(endpoint, {withCredentials: true})
        fetched = r.data
        fetched.ok = true
    } catch(e) {
        if (e.response && e.response.data) {
            fetched = e.response.data
        } else {
            fetched.message = e.message
        }
        fetched.ok = false
    }
    return fetched
}


async function apiGetPet(fluid) {
    // Get pet from App
    let fetched = await apiGet('/fluid/'+fluid+'/?translate=true&lang=IT')
    if (!fetched || !fetched.ok) {
        console.error(fetched.message)
        return null
    }

    // docs
    if (Array.isArray(fetched.attributes.doc) && fetched.attributes.doc.length > 0) {
        fetched.doc = fetched.attributes.doc    
        // separate notes (doc type 90-99)
        fetched.notes = fetched.doc.filter( d => {return d.doccode=='0x5a'})
        fetched.doc = fetched.doc.filter( d => {return d.doccode!='0x5a'})
        // profile picture
        fetched.profilepic = fetched.doc.filter( d => {return d.doccode =='0x59'})
        fetched.doc = fetched.doc.filter( d => {return d.doccode!='0x59'})
    }
    delete fetched.attributes.doc

    // Get profile pic urls
    if (fetched.profilepic && fetched.profilepic.length>0) {
        fetched.profilepicurl = []

        let proms = []
        for (let pic of fetched.profilepic) {
            proms.push( apiGet('/docs/' + pic.dochash))
        }
        let resps = await Promise.allSettled(proms)
        for (let resp of resps) {
            if (resp.status != 'fulfilled') {
                console.error(resp.value.message)   
            } else {
                if (resp.value.ok) {
                    fetched.profilepicurl.push(resp.value.data.url)
                }
            }
        }
    }

    // owners
    fetched.owners = fetched.attributes.owner?.reverse() || []
    delete fetched.attributes.owner

    // *** REMOVE PRIVATE and EXTERNAL DOCS ***
    delete fetched.attributes.doc_private
    delete fetched.attributes.doc_external

    // scores
    fetched.scores = fetched.attributes.scores
    delete fetched.attributes.scores

    // Translate & beautyfy, Return
    fetched.attributes.fluid = fetched.fluid
    fetched.rawattributes = fetched.attributes;

    return fetched
}


async function apiUserExists(fluid, email) {
    let qs = "?"
    if (fluid) {
        qs += "id="+fluid
        if (email) {
            qs +="&"
        }
    }
    if (email) {
        qs +="email="+email
    }

    return apiGet("/user/exists"+qs)
}

async function apiUserHasCard(fluid) {
    return apiGet("/user/hascard/"+fluid)
}

async function apiDecodeToken(token) {
    return apiGet("/user/decode/"+token)
}



async function apiGetUser(fluid) {
    return apiGet("/user/"+fluid)
}

async function apiGetEmailIsValid(email) {
    let endpoint="/util/isvalidmail/"+email
    let fetched = await apiGet(endpoint)
    if (!fetched.valid || fetched.valid == undefined || fetched.valid == false) {
        return false
    }
    return true
}

// --- SHOP API FUNCTIONS -------------------------------------------------------------------------

async function apiShopSendFeedback(fb) {
    return await apiPost(fb, config.ApiShopUrl+'/extra/feedback' )
}

async function apiShopCheckOTToken(token) {
    return await apiGet(config.ApiShopUrl+'/extra/onetime/'+token, true)
}

async function apiShopIsLogged() {
    return await apiGet(config.ApiShopUrl+'/extra/logged', true)
}

async function apiShopConfirm(qs) {
    return await apiGet(config.ApiShopUrl+'/buy/confirm?'+qs, true)
}

async function apiShopGetPromos(fluid) {
    return await apiGet(config.ApiShopUrl+'/promo/for/'+fluid, true)
}

async function apiShopGetPromoStats() {
    return await apiGet(config.ApiShopUrl+'/promo/stats', true)
}


async function apiShopGetShop(name) {
    return await apiGet(config.ApiShopUrl+'/promo/shop/'+name, true)
}

async function apiShopGetList() {
    return await apiGet(config.ApiShopUrl+'/promo/shops', true)
}


// --- USER FUNCTIONS ------------------------------------------------------------------------------


function storeUser(user) {
    let string = JSON.stringify(user)
    localStorage.setItem('FL_Shop_User', string)
}

function clearUser() {
    localStorage.removeItem('FL_Shop_User')
}

function getUser() {
    try {
        return JSON.parse(localStorage.getItem('FL_Shop_User'))
    } catch {
        console.error("User 404")
        return null
    }
}

function updateUser(updates) {
    let user = getUser() || {}
    let merged = {...user, ...updates}
    storeUser(merged)
    return merged
}

// --- XXX FUNCTIONS ------------------------------------------------------------------------------


function sha256sum(data) {
    return sha256(data);
}

// Turns a EU date 'dd/mm/yyyy' into a JS date object since parse il racist
function euDate2Date (eudate) {
    let parts = eudate.split("/");
    let date = new Date(parseInt(parts[2], 10),
        parseInt(parts[1], 10) -1,  // the month is 0-indexed
        parseInt(parts[0], 10));
    return date
}


function date2EUDate (date) {
    let d = Date.parse(date)
    if (!isNaN(d)) {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' }
        return (new Date(d)).toLocaleDateString('it-IT', options)
    }
    return undefined
}

function isValidChip (pet_tag, iso3166 = null) {
    for (let r of config.Tag_RegExp) {
        let rx = new RegExp(r)
        if (pet_tag.match(rx) !== null) {
            // Check if matches ISO3166 country code according to ISO 11784 & 11785
            // First 3 chip numbers must match ISO3166 country code
            // or:
            // 999 : test
            // 9xx : manufacturer specific code
            if (iso3166 && pet_tag.length == 15 && pet_tag[0] != '9') {
                // Is ISO 11784 & 11785
                let tag_3166 = pet_tag.substring(0,3)
                if (iso3166 != parseInt(tag_3166)) {
                        console.error("ISO Mismatch")
                        return false    
                }
            }
            return true
        }
    }
    return false
}

function isValidFiscalCode(code, name=null) {
    if (!code) {
        return false
    }
    // Check regex with valid letter for month
    var cf = code.toUpperCase();
    if (!config.Fiscal_RegExp.test(cf))
    {
        return false;
    }

    // Check valid values for day
    let day = parseInt(cf.substr(9,2))
    if ( (day <= 0) || (day > 71) || (day > 31 && day < 41) ) {
        return false
    }

    // check the letters in the name
    if (name) {
        let l6 = (code.substr(0,6)).replaceAll('X','')
        for (let chr of l6) {
            if (!name.toUpperCase().includes(chr)) {
                return false
            }
        }
    }

    // Check CRC Char
    var set1 = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ"
    var set2 = "ABCDEFGHIJABCDEFGHIJKLMNOPQRSTUVWXYZ"
    var setpari = "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
    var setdisp = "BAKPLCQDREVOSFTGUHMINJWZYX"
    var s = 0
    for( let i = 1; i <= 13; i += 2 )
      s += setpari.indexOf( set2.charAt( set1.indexOf( cf.charAt(i) )))
    for( let i = 0; i <= 14; i += 2 )
      s += setdisp.indexOf( set2.charAt( set1.indexOf( cf.charAt(i) )))
    if ( s%26 != cf.charCodeAt(15)-'A'.charCodeAt(0) ) {
        return false
    }

    return true
}


function capitalizeWords(str) {
    return str
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
}


// --- IMG/FILE/BLOB FUNCTIONS --------------------------------------------------------------------

 function dataURItoBlob (dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1]);
    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);
    // create a view into the buffer
    var ia = new Uint8Array(ab);
    // set the bytes of the buffer to the correct values
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    // write the ArrayBuffer to a blob, and you're done
    var blob = new Blob([ab], {type: mimeString});
    return blob;
}

function getReducedSize(X,Y) {
    let x
    let y
    if (X>Y) {
        if (X<=config.MaxImageSide) {
            return [X,Y]
        }
        x = config.MaxImageSide
        y = (Y*config.MaxImageSide)/X
    } else {
        if (Y<=config.MaxImageSide) {
            return[X,Y]
        }
        y = config.MaxImageSide
        x = (X*config.MaxImageSide)/Y
    }
    return [x,y]
}

function shuffleArray(array) {
    for (var i = array.length - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        var temp = array[i];
        array[i] = array[j];
        array[j] = temp;
    }
}



// --- EXPORTS ------------------------------------------------------------------------------------

export {
    validBytes,
    validAddress,
    // Main App API calls
    apiGet,
    apiPost,
    apiGetPet,
    apiGetUser,
    apiUserExists,
    apiUserHasCard,
    apiDecodeToken,
    apiGetEmailIsValid,

    // Shop API calls
    apiShopSendFeedback,
    apiShopConfirm,
    apiShopGetPromos,
    apiShopGetPromoStats,
    apiShopGetShop,
    apiShopGetList,
    apiShopCheckOTToken,
    apiShopIsLogged,

    // User functions
    storeUser,
    clearUser,
    getUser,
    updateUser,

    // Various & Image functions 
    isValidFiscalCode,
    isValidChip,
    sha256sum,
    euDate2Date,
    date2EUDate,
    capitalizeWords,
    dataURItoBlob,
    getReducedSize,
    shuffleArray
}