import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/Home.vue'
import BuyView from '../views/Buy.vue'
import CardView from '../views/Card.vue'
import SuccessView from '../views/Success.vue'
import CancelView from '../views/Cancel.vue'
import TYouView from '../views/TYou.vue'
import ByeView from '../views/Goodbye.vue'

import PromoView from '../views/Promo.vue'
import BizView from '../views/Biz.vue'

// Need this to check authenticaion
// import { auth } from '../common/firebase'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/success',
    name: 'success',
    component: SuccessView
  },
  {
    path: '/cancel',
    name: 'cancel',
    component: CancelView
  },
  {
    path: '/thankyou',
    name: 'thankyou',
    component: TYouView
  },  
  {
    path: '/goodbye/:customer_email?',
    name: 'goodbye',
    component: ByeView,
    props : true
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // },
  {
    path: '/card/:paymentId?',
    name: 'card',
    component : CardView,
    props : true
    /*
      Card page has to be protected
      Add the meta to signal this to the router
      See below to get how its used later

      meta: {
        requiresAuth : true
      }
    */
  },
  {
    path: '/buy/:fluid?',
    name: 'buy',
    component : BuyView,
    props : true
    /*
      Card page has to be protected
      Add the meta to signal this to the router
      See below to get how its used later

      meta: {
        requiresAuth : true
      }
    */
  },

  {
    path: '/promo/:token?',
    name: 'promo',
    component : PromoView,
    props : true
  },
  {
    path: '/biz/:name?',
    name: 'biz',
    component : BizView,
    props : true
  },


]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// This is the part that does the filtering of the pages under auth
// router.beforeEach((to, from, next) => {

//   // Send to login if page requires it
//   if (to.meta.requiresAuth && !auth.currentUser) {
//     next('/login')
//     return
//   }

//   // Cant re-login if already logged
//   if (to.path == '/login' && auth.currentUser) {
//     return
//   }

//   // Go to the destination page, no filtering happened
//   next()
// })

export default router
