<template>
	<div class="container">
		<div class="row">
			<div class="col-12">
				<div class="separator-blank q-my-lg"></div>
				<h2>Oh no! <q-icon name="pets"></q-icon></h2>
				<h3>Il tuo pagamento non è passato!</h3>
			</div>
		</div>
		<div class="separator-blank q-my-md"></div>
		<div class="row">
			<div class="col-xs-1 col-sm-2 col-md-3 col-lg-3 col-xl-3"></div>

			<!-- Compilation Completed -->
			<div class="col-xs-10 col-sm-8 col-md-6 col-lg-6 col-xl-6 text-justify">
                <p>Qualcosa è andato storto, forse il sistema di pagamento che ha avuto qualche problema?</p>
                <p>Nessun problema: puoi provare a usare un altro sistema di pagamento.</p>
				<div class="separator-blank q-my-xl"></div>
				<div class="text-center">
				<h3>Non scoraggiarti</h3>
					<q-btn 
						icon="arrow_forward" 
						label="Riprova" 
						color="green" 
						size="lg"
						v-close-popup @click="retry()">
					</q-btn>
				</div>
			</div>


			<div class="col-xs-1 col-sm-2 col-md-3 col-lg-3 col-xl-3"></div>
		</div>
		<div class="row bg-dogGirl" style="height: 550px;">
			&nbsp;
		</div>


	</div>
</template>



<script>
export default {
  name: 'CancelView',
  components: {
  },

  methods : {
			retry : function () {
				this.$router.push('/buy')
			}
		},
}
</script>
