<template>
	<div class="container">
		<div style="height: 11vh; padding: 3vh 0;">
			<LogoImg/>
		</div>

		<div v-if="shoplist.length==0">
			<div class="separator-blank q-py-lg"></div>
			<div class="infogroup-dark">
				<h3>Al momento non ci sono offerte attive.</h3>
				<h3>Ti preghiamo di provare più avanti.</h3>
			</div>
			<div class="separator-blank q-py-lg"></div>
		</div>


		<div class="row"  v-if="loading">
			<div class="col-12 text-center">
				<q-spinner-ios
					color="green"
					size="6em"
					:thickness="9"
				></q-spinner-ios>
			</div>
		</div>
		<div class="row" v-else>
			<!-- LEFT WHITE COLUMN -->
			<div class="col">
			</div>
			<!-- CENTER COLUMN -->
			<div class="col-xs-10 col-sm-8 col-md-6 col-lg-6 col-xl-6 text-left" v-if="shop">
				
				<!-- SHOP DESCRIPTION -->
				<h2>{{ shop.shop.biz_name }}</h2>
				<div class="separator-blank q-my-lg"></div>
				<div class="infogroup-dark container text-left">
					<div class="row">
						<div class="col-12 text-right">
							<q-badge color="green" align="middle"> {{ shop.shop.category }}</q-badge>
						</div>
					</div>
					<div class="row">
						<div class="col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 col-xxl-3 self-center text-center">
							<a :href="shop.shop.site_url" target="_blank">
								<q-img
									width="100%"
									:src=shop.shop.logo_url
								></q-img>
							</a>
						</div>
						<div class="col-xs-12 col-sm-12 col-md-8 col-lg-9 col-xl-9 col-xxl-9 q-px-md">
							<span 
								v-html="shop.shop.business_description"
							></span>
						</div>
					</div>
					<div class="row">
						<div class="col-12 text-right">
							<span v-for="(tag, idx) in shop.shop.business_tags" :key='idx' class="q-mx-xs">
								<q-badge color="green" align="middle"> {{ tag }}</q-badge>
							</span>
						</div>
					</div>
				</div>

				<div class="separator-blank q-my-md"></div>

				<!-- SHOP INFO -->
				<div class="infogroup">
					<div class="row">
						<div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 text-left">
							<div class="text-center"><h3>Contatti:</h3></div>
							<div v-if="shop.shop.email">								
								<q-btn 
									color="green" 
									icon="mail" 
									size="10px" 
									label="" 
									class="q-ma-sm"
									@click="sendEmail(shop.shop.email)"
								></q-btn>invia email<br/>
							</div>
							<div v-if="shop.shop.site_url">								
								<q-btn 
									color="green"
									icon="language"
									size="10px"
									label="" 
									class="q-ma-sm"
									@click="gotoSite(shop.shop.site_url)"
								></q-btn>vai al sito<br/>
							</div>
							<div v-if="shop.shop.phone">								
								<q-btn 
									color="green"
									icon="phone"
									size="10px"
									label=""
									class="q-ma-sm"
									@click="phoneCall(shop.shop.phone)"
								></q-btn>chiama<br/>
							</div>
						</div>
						<div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 col-xxl-6 self-center text-center">
							<div v-if="maps_url">
								<h3>Dove si trova:</h3>
								<div class="text-center">
									<iframe 
										width="100%"
										height="250"
										frameborder="0" style="border:0"
										referrerpolicy="no-referrer-when-downgrade"
										v-bind:src="maps_url"
										allowfullscreen
									>
									</iframe>
								</div>
							</div>
							{{  shop.shop.address }}
						</div>
					</div>
				</div>

				<hr>

				<!-- PROMOS -->
				<div v-if="shop.promos.length>0 && logged">
					<h3>Promozioni attive:</h3>
					<div v-for="(promo, idx) in shop.promos" :key="idx" >
						<!-- DISPLAY PROMO -->
						<PromoCard 
							v-bind:promo="promo"
							v-bind:hascard="hascard"
						/>
					</div>
				</div>

				<div class="separator-blank q-my-xl"></div>
			</div>
			
			<!-- SHOPS TABLE -->
			<div class="col-xs-10 col-sm-8 col-md-6 col-lg-6 col-xl-6 text-left" v-if="shoplist">
				<div class="separator-blank q-ma-lg"></div>
				Qui trovi l'elenco delle aziende con cui abbiamo o stiamo per fare delle parnership
				divise per settori

				<div v-for="(cat, idx) in Object.keys(shoplist)" :key="idx">
					<div class="separator-blank q-my-lg"></div>
					<h3>{{ cat }}:</h3>
					<div class="row">
						<div 
							v-for="(shop, idy) in Object.values(shoplist[cat])" :key="idy"
							class="col-xs-4 col-sm-4 col-md-3 col-lg-2 col-xl-2 text-center q-mx-sm"
						>
							<div 
								class="vertical-middle"
							>
								<a :href="shop.biz_page">
									<q-img
										width="90%"
										:src=shop.logo_url
										:class="{gray : shop.running==null}"
										ratio="1"
										fit="contain"
									>
										<!-- <q-badge 
											v-if="shop.running"
											color="green"
											floating
											style="top: 1px; right:1px; padding: 5px;"
											v-bind:label="shop.running"
										>
										</q-badge> -->
									</q-img>
								</a>
							</div>
							<div class="vertical-bottom biz_name">
								{{ shop.biz_name }}
							</div>
						</div>
					</div>
					<hr>
				</div>
			</div>


			<!-- RIGHT WHITE COLUMN -->
			<div class="col bg-dogGirl"></div>
		</div>
	</div>
</template>



<script>
import * as config from '../common/config'
import * as tools from '../common/tools'

import LogoImg from "../components/Logo"
import PromoCard from '../components/PromoCard'

export default {
    name: 'BizView',
    components: {
		LogoImg,
		PromoCard
    },

	props : {
		name 		: {
			type		: String,	
			required	: true,
			default		: null
		},
	},

	data () {
		return {
			loading			: true,
			logged			: false,
			hascard			: false,
			shop			: false,
			maps_url		: false,
			key             : config.MapsApiKey,

			shoplist		: false
		}
	},

    methods: {
		loadUser : async function() {
			let jwt = await tools.apiShopIsLogged()
			this.logged = jwt.ok

			let hascard = await tools.apiUserHasCard(jwt.user_fluid)
			if (!hascard.exists) {
				return false
			}
			this.hascard = hascard.hascard

			return true
		},

		loadBusiness : async function() {
			if (!this.name || !this.logged) {
				return false
			}

			let shop
			try {
				shop = await tools.apiShopGetShop(this.name)
			} catch (e) {
				return false
			}
			if (!shop.ok) {
				return false
			}

			// Check promo data
			for (let promo of shop.promos) {
				if (!promo.banner_url || promo.banner_url == '') {
					promo.banner_url = promo.logo_url
				}
			}

			// Copy over 
			this.shop = JSON.parse(JSON.stringify(shop))

			if (shop.shop.address && shop.shop.address.length > 3) {
				this.maps_url = "https://www.google.com/maps/embed/v1/place?key="+this.key+"&q="+shop.shop.address+"&maptype=satellite&zoom=17"
			}

			return true
		},

		loadBusinesList : async function() {
			// Get shops list
			let shoplist = await tools.apiShopGetList()

			// Add local link only to logged users
			if (this.logged) {
				for (let shop of shoplist) {
					if(shop.running) {
						shop.biz_page="/biz/"+encodeURIComponent(shop.biz_name)
					}
				}
			}

			// group by cathegory
			let categories = shoplist.map( x=> {return x.category})
			categories = [...new Set(categories)].sort()
			let grouped = {}
			for (let cat of categories) {
				grouped[cat] = shoplist.filter( x=>{return x.category === cat} )
				tools.shuffleArray(grouped[cat])
			}
			// Copy over
			this.shoplist = JSON.parse(JSON.stringify(grouped))
		},

		// Button actions
		sendEmail : function (email) {
			let mail_url = 'mailto:?subject='+encodeURIComponent('Una mail da FaunaLife')+'\nURL: '+encodeURIComponent(email)
			window.open(mail_url, null)
		},
		gotoSite : function (site_url) {
			window.open(site_url, '_blank')
		},
		phoneCall : function (phone) {
			window.open('tel:'+phone, '_blank')
		},
    },

	// Lifecycle Hooks
	async mounted() {
		window.scrollTo(0,0)
		// Get user
		let logged = await this.loadUser()

		// Go single shop or list ?
		if (logged && this.name) {
			// Load single business
			if (await this.loadBusiness()) {
				this.loading = false
				return				
			}
			// Failed -> defaults to businesses list
		} 
		if (this.loadBusinesList()) {
			this.loading = false
		}
	}



}
</script>

<style scoped>
	.gray {
		filter: grayscale();
		opacity: 0.3;
	}

	.biz_name {
		color: var(--fl-gray-2);
		font-size: 16px !important;
		font-weight: 0 !important;
	}
</style>