<template>
    <!-- DISPLAY PROMO -->
    <div class="row infogroup-dark" v-bind:class="{mesh_bg : promo.premium>0}" v-if="hascard || promo.premium == 0">
        <!-- BANNER/LOGO -->
        <div 
            class="col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 col-xxl-3 self-center text-center"
            v-on:click='goToURL(promo.link_url)'
        >
            <q-img
                width="100%"
                :src=promo.banner_url
            ></q-img>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-8 col-lg-9 col-xl-9 col-xxl-9 q-px-md">
            <!-- TOP : ShopName and badges -->
            <div 
                class="row reverse-wrap"
                v-on:click='goToShop(promo.biz_name)'
            >
                <div class="col-xs-12 col-sm-12 col-md-8 col-lg-10 col-xl-10 col-xxl-10 text-left q-px-md">
                    <div style="float: left;">
                        <h3  v-bind:class="{white: promo.premium>0}">{{ promo.biz_name }}</h3>
                    </div>
                    <div style="float: left;">
                        &nbsp;<q-icon name="open_in_new" color="grey-5" ></q-icon>
                    </div>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-4 col-lg-2 col-xl-2 col-xxl-2 text-right">
                    <q-badge color="green" align="middle" class="q-mx-xs"> {{ promo.category }}</q-badge>
                    <q-badge
                        v-if="promo.premium > 0"
                        color="orange" 
                        align="middle"
                    >
                        PREMIUM&nbsp;
                        <q-icon name="workspace_premium" color="white"></q-icon>
                    </q-badge>

                </div>
            </div>
            
            <!-- MIDDLE : Promo Description -->
            <div 
                class="row q-pa-md text-justify"  
                v-bind:class="{white: promo.premium>0}"
                v-on:click='goToURL(promo.link_url)'
            >
                <span 
                    v-html="promo_text"
                ></span>
            </div>
            <!-- LOWER : Tags -->
            <div class="row">
                <div class="col-12 text-right">
                    <span v-for="(tag, idx) in promo.promo_tags" :key='idx' class="q-mx-xs">
                        <q-badge color="green" align="middle"> {{ tag }}</q-badge>
                    </span>
                </div>
            </div>
        </div>
    </div>

    <!-- HIDEN PROMO -->
    <div class="row infogroup-dark" v-bind:class="{mesh_bg : promo.premium===1}" v-else>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 col-xxl-3 self-center">
            <q-avatar square size="200px" font-size="180px" text-color="grey-7" icon="help"></q-avatar>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-8 col-lg-9 col-xl-9 col-xxl-9">
            <div class="row reverse-wrap">
                <div class="col-xs-12 col-sm-12 col-md-8 col-lg-9 col-xl-9 col-xxl-9 text-left q-px-md">
                    <h3>?????????</h3>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-4 col-lg-3 col-xl-3 col-xxl-3 text-right q-px-md">
                    <q-badge color="green" align="middle"> {{ promo.category }} </q-badge>
                    <q-badge
                        color="orange" 
                        align="middle"
                    >
                        PREMIUM&nbsp;
                        <q-icon name="workspace_premium" color="white"></q-icon>
                    </q-badge>
                </div>
            </div>
            <!-- <div class="separator-blank q-my-lg"></div> -->
            <div class="row q-pa-md text-justify">
                Offerta riservata ai possessori di MyPetCard
            </div>
            <div class="row">
                <div class="col-12 text-right">
                    <span v-for="(tag, idx) in promo.promo_tags" :key='idx' class="q-mx-xs">
                        <q-badge color="green" align="middle"> {{ tag }}</q-badge>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import * as tools from '../common/tools'
    export default {
        name : 'PetProfileCard',
        
        components: {
        },

        props : {
            promo : {
                type: Object,
                required : true,
            },
            // Editable is true only in the Pet Scan page
            hascard : {
                type : Boolean,
                required : false,
                default : false,
            }
        },

        data () {
            return {
                promo_text:''
            }
        },

        computed : {  
        },

        methods: {
            goToShop : function (shop) {
                // Request onetimetoken to access shop
                // let url = await tools.apiGetOneTimeURL('shop')            
                // console.error("URL", url.url)
                // window.open(url.url);
                this.$router.push('/biz/'+shop)
            },

            goToURL : function (url) {
                let qstring
                let user = tools.getUser()
                if (user) {
                    qstring = "?fluid=" + (user.user || user.user_fluid)
                }
                window.open(url+qstring, '_blank');
            }
        },

        mounted() {
            // Change typography of text according to card type
            if (this.promo.premium>0 && this.hascard) {
                this.promo_text = this.promo.promo_description.replace(/<b>/ig, '<b style="color: white !important;">')
            } else {
                this.promo_text = this.promo.promo_description
            }
        }
    }
</script>


<style scoped>
    .mesh_bg {
        background-image: url("../assets/bg_mesh_hor.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-color: var(--fl-green-4);
        margin : 10px;
        padding :10px;
        border-style: solid;
        border-width: 1px;
        border-radius:18px;
        border-color: var(--fl-gray-3);
        flex: 1 1 auto;
        max-width: 100%;
        overflow-x: auto;
    }

    .white {
        color: white !important;
        text-shadow: 2px 4px 4px rgba(46,91,73,0.6);
    }
</style>