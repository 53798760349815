<template>
	<div class="container">
		<div class="row">
			<div class="col-12">
				<div class="separator-blank q-my-lg"></div>
				<h2>Grazie! <q-icon name="pets"></q-icon></h2>
				<h3>per per il tuo feedback</h3>
			</div>
		</div>
    <div class="separator-blank q-my-md"></div>
		<div class="row">
			<div class="col-xs-1 col-sm-2 col-md-3 col-lg-3 col-xl-3"></div>

			<!-- Compilation Completed -->
			<div class="col-xs-10 col-sm-8 col-md-6 col-lg-6 col-xl-6 text-justify">
                <p>Abbiamo preso nota della tua risposta, faremo il possibile per darti un riscontro a stretto giro.</p>

                <p>Se hai altre necessità <span @click=" emailSupport()"><b>puoi rivolgerti al nostro supporto</b></span></p>

				<div class="separator-blank q-my-xl"></div>
				<h3>Cosa puoi fare ora ?</h3>
				<p><b>Accedi al tuo profilo sulla App di FaunaLife</b> e completa i dati del tuo pet
				inserendo note, e documenti.</p>
				<p>Se hai altri pet potrai aggiungerli sulla App e acquistare la loro <b>MyPetCard</b>
				direttamente dalla app.</p>
				<div class="text-center">
					<q-btn 
						icon="arrow_forward" 
						label="Vai  alla  App" 
						color="green" 
						size="lg"
						v-close-popup @click="go_to_app()">
					</q-btn>
				</div>
			</div>


			<div class="col-xs-1 col-sm-2 col-md-3 col-lg-3 col-xl-3"></div>
		</div>
		<div class="row bg-dogGirl" style="height: 550px;">
			&nbsp;
		</div>

  </div>
</template>



<script>
// import axios from 'axios'
import * as tools from '../common/tools'
import * as config from '../common/config'

export default {
    name: 'TYouView',
    components: {
    },

    methods: {
        emailSupport : function () {
            let mail_link = 'mailto:support@fauna.life?subject=Richiesta%20supporto'
            window.open(mail_link, null);
        },

        sendQueryString : async function () {
            // Get QueryString as object
            if (! this.$route.query || JSON.stringify(this.$route.query) === "{}") {
                return
            }

			await tools.apiShopSendFeedback(this.$route.query)

            // // Send Query String to Backend
			// let r
            // let endpoint = config.Api-ShopUrl+'/extra/feedback'
			// try {
			// 	r = await axios.post(endpoint, this.$route.query, {
			// 							withCredentials: true,
			// 							// validateStatus: status => {return true;}    		//TRICK: consider all responses valid...
			// 							validateStatus: status => {return status==status}	// Because Vue complains about unused vars...
			// 						})

			// 	if (r.status != 200) {                                              // ... and handle them manually later
			// 		console.error(JSON.stringify(r,null,4))
			// 	}
			// } catch (e) {
			// 	console.error("Error: ", e.message)
			// }
        },

		go_to_app : function () {
			let url=config.AppBaseUrl + "/?flmail="+this.customer_email
			window.open(url)
		},

    },

	// Lifecycle Hooks
	mounted() {
		window.scrollTo(0,0)
		this.sendQueryString()
	},
}
</script>
