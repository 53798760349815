<template>
  <component :is="layout">
    <router-view :key="$route.fullPath"  @evLogged="redrawHeader" />
  </component>
  <!-- <router-view/> -->
</template>

<script>
    import DefaultLayout from './components/Layout/DefaultLayout'
    // import WithoutLogoLayout from './components/Layout/WithoutLogoLayout'
    const defaultLayout = 'DefaultLayout'
    
    export default {
        name: 'App',
        components: {
            DefaultLayout,
            // WithoutLogoLayout
        },
        computed: {
          layout () {
            const layout = (this.$route.meta.layout || defaultLayout)
            // console.log(this.$route, {layout})
            return layout
          }
        },
        data ()  {
          return {
            hkey : 0
          }
        },

        methods: { 
          redrawHeader : function () {
            // Change che key of the router element to for re-rendering
            this.hkey++            
          }
        }
    }
</script>

<style>

:root {
  --fl-green-1  : #3aa575;
  --fl-green-2  : #42b983;
  --fl-green-3  : #65B687;
  --fl-green-4  : #A0D1B5;

  --fl-pink-1   : #EFB097;
  --fl-red-1    : #FF0000;
  --fl-yellow-1 : #ffbb00;
  --fl-blue-1   : #174af4;


  /* --fl-font-g1  : #65B687; */
  --fl-font-G1  : #595959;
  --fl-font-G2  : #393939;

  --fl-gray-1   : #6b6b6b;
  --fl-gray-2   : #9b9b9b;
  --fl-gray-3   : #d1d1d1;
  --fl-gray-4   : #F9F9F9;
  --fl-gray-5   : #FBFBFB;

  --fl-whatermark : #eee;
}

h1 {
  margin : 5px !important;
  /* https://royalfig.github.io/fluid-typography-calculator/ */
  font-size: clamp(4.5rem, -0.875rem + 8.333vw, 6rem) !important;
  font-weight: 700 !important;
  color : var(--fl-green-3);
}

h2 {
  margin : 5px !important;
  font-size: clamp(3rem, -0.875rem + 8.333vw, 4rem) !important;
  font-weight: 700 !important;
  color : var(--fl-green-3);
}


h3 {
  margin : 0px !important;
  font-size: clamp(1.15rem, 1.0625rem + 0.78125vw, 2rem) !important;
  font-weight: 700 !important;
  color : var(--fl-green-3);
}

h4 {
  margin : 0px !important;
  /* 20 -> 34 [360 - 1919] */
  font-size: clamp(1rem, 0.875rem + 0.5208333333333333vw, 1.5rem) !important;
  font-weight: 700 !important;
  color : var(--fl-green-3);
}

.font-small {
  margin : 0px !important;
  font-size: 16px !important;
  font-weight: 0 !important;
  color : var(--fl-green-3);
}


#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--fl-font-G1);
  font-size: clamp(1rem, 0.875rem + 0.5208333333333333vw, 1.5rem) !important;
}

a {
  color : var(--fl-green-3);
  text-decoration: none;
}

a:hover {
  color : var(--fl-green-4);
  text-decoration: none;
}

a.router-link-exact-active {
  color: var(--fl-green-2) !important;
}

b {
  font-weight: 600 !important;
  color : var(--fl-green-3);
}


#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

div.petbg {
    /* background-image: url('/img/petbg.png'); */
    background-image: url('@/assets/petbg.png');
    display: block;
}

.bg-Cats, .bg-dogGirl {
		background-repeat: no-repeat;	
		/* filter: blur(5px);
		box-shadow: 0 0 0 100vmax rgba(0, 0, 0, .3); */
	}

	/* Large Screens */
	@media screen and (min-width: 1024px) {
		.bg-dogGirl {
			background-image: url("@/assets/DogGirl.png");
			background-position: right bottom;
			/* background-repeat: no-repeat;
			filter: blur(5px);
			box-shadow: 0 0 0 100vmax rgba(0, 0, 0, .3); */
		}

		.bg-Cats {
			background-image: url("@/assets/Cats.png");
			background-position: left bottom;
			/* background-repeat: no-repeat;
			filter: blur(5px);
			box-shadow: 0 0 0 100vmax rgba(0, 0, 0, .3); */
		}
	}

	/* Small Screens */
	@media screen and (max-width: 1023px) {
		.bg-dogGirl {
			background-image: none;
		}

		.bg-Cats {
			background-image: none;
		}
	}


  .infogroup {
    margin : 10px;
    padding :10px;
    border-style: none;
    flex: 1 1 auto;
    max-width: 100%;
    overflow-x: auto;
  }
  
  .infogroup-dark {
    margin : 10px;
    padding :10px;
    background-color: var(--fl-gray-4);
    border-style: solid;
    border-width: 1px;
    border-radius:18px;
    border-color: var(--fl-gray-3);
    flex: 1 1 auto;
    max-width: 100%;
    overflow-x: auto;
  }
  
</style>
