<template>
	<div class="row" style="min-height: 94vh;">
		
		<!-- Card Preview  -->
		<div class="col-xs-12 col-sm-12 col-md-6 col-lg-5 col-xl-5 bg-Cats" style="min-height: 100%;">
			<div v-bind:class="{blurred : blurred_on}">

				<div style="height: 11vh; padding: 3vh 0;">
					<LogoImg/>
				</div>

				<div class="scalable-wrapper" style="min-height: 40vh;">
					<div class="card_background card_font">
						<div class="card_data">
							<p class="datapos_name">
								{{data.pet_name|| '&nbsp; '}}
							</p>
							<p class="datapos_dob">
								{{data.birth_date}}
							</p>
							<p class="datapos_sex">
								{{data.gender}}
							</p>
							<p class="datapos_species">
								{{sel_species}}
							</p>
							<p class="datapos_breed">
								{{short_breed[0] || ' '}}
							</p>
							<p class="datapos_breed2">
								{{short_breed[1] || '&nbsp; '}}
							</p>
							<p class="datapos_owner">
								{{data.owner_name}}
							</p>

							<img class="datapos_qr_code"
								:src="qr_url"
								alt="QRCode"
							/>	

						</div>
						<div class="datapos_id_picture_container">
							<img  class="datapos_id_picture"
									v-if="is_cropping==this.CROP_DONE && data.profilePicture"
									key="data.profilePicture"
									:src="data.profilePicture"
									alt="Avatar"
							/>
						</div>
					</div>
				</div>

			</div>
		</div>
		
		<!-- Fields form -->
		<div class="col-xs-12 col-sm-12 col-md-6 col-lg-7 col-xl-7 bg-dogGirl" style="min-height: 100%;">
			<div class="row">
				<div class="col-xs-12 col-sm-12 col-md-12 col-lg-10 col-xl-8">
					<div v-bind:class="{blurred : blurred_on}">
	
						<div>
							<h2>Acquista MyPetCard</h2>
							<h3 v-if="step<5">Inserisci i dati del tuo pet</h3>
						</div>
	
						<!-- Spinner Step 0 -->
						<div class="step items-center q-my-md q-mx-xl text-center" v-if="step==0">
							<!-- Spinner -->
							<q-spinner-ios
								color="green"
								size="6em"
								:thickness="9"
							></q-spinner-ios>
						</div>
	
						<!-- Name Step 1 -->
						<div class="step items-center text-left q-my-md q-mx-xl" v-if="step==1">
							Nome del PET
							<q-input id="data.pet_name" v-model="data.pet_name" type="text" 
								outlined 
								label-color="green"
								color="green"
								bg-color="white"
								hint="Inserisci il nome (max 20 caratteri)" 
								label=""
								maxlength=20
								v-on:update:model-value="changed_pet_name()"
							>
								<template v-slot:after>
									<q-avatar :color='icm_name' text-color="white" icon="pets"></q-avatar>
								</template>
							</q-input>
	
							<!-- Species * -->
							Specie
							<q-select id="sel_species" v-model="sel_species" 
								v-bind:options="opt_species"
								v-on:update:model-value="update_breed_list()"
								outlined 
								label-color="green"
								bg-color="white"
								color="green"
								label="" 
								hint="Seleziona la specie del tuo animale"
								>
								<template v-slot:after>
									<q-avatar :color='icm_species' text-color="white" icon="pets"></q-avatar>
								</template>                     
							</q-select>
	
							Razza
							<q-select v-model="sel_breed" 
								v-bind:options="opt_breed_filtered"
								v-on:update:model-value="update_short_breed()"
								outlined 
								label-color="green"
								color="green"
								bg-color="white"
								label=""
								use-input
								@filter="filterBreed"
								hint="Seleziona la razza (scrivi e/o scegli)"
							>
								<template v-slot:after>
									<q-avatar :color='icm_breed' text-color="white" icon="pets"></q-avatar>
								</template>                                         
							</q-select>
	
							<!-- DOB * -->		
							Data di Nascita
							<q-input v-model="data.birth_date" mask="##/##/####" :rules="[ dob_update() || 'Data errata']"
								outlined
								label-color="green"
								bg-color="white"
								color="green"                        
								hint="Inserisci la data di nascita"
								label=""
								@update:model-value = "dob_update()"
							>
								<template v-slot:append>
									<q-icon name="event" class="cursor-pointer">
										<q-popup-proxy 
											ref="qDateProxy" 
											transition-show="scale" 
											transition-hide="scale"
											@show=" setWindowBlur(true)"
											@hide=" setWindowBlur(false)"
										>
											<q-date v-model="data.birth_date" color="green"
												v-bind:navigation-max-year-month="max_year_month"
												mask="DD/MM/YYYY"
												@update:model-value = "dob_update()"
											>
												<div class="row items-center justify-end">
													<q-btn v-close-popup label="Close" color="green" flat></q-btn>
												</div>
											</q-date>
										</q-popup-proxy>
									</q-icon>
								</template>
								<template v-slot:after>
									<q-avatar :color='icm_dob' text-color="white" icon="pets"></q-avatar>
								</template>      
							</q-input>				
							<p />
							
							Genere
							<p />
							<div class="row ">
								<div class="col"></div>
								<div class="col-4">
									<q-radio 
										keep-color 
										v-model="data.gender" 
										val="M"   
										label="Maschio" 
										color="green" 
										dense
										@update:model-value = "gender_change()"
									>
									</q-radio>
								</div>
								<div class="col-4">
									<q-radio 
										keep-color 
										v-model="data.gender" 
										val="F" 
										label="Femmina" 
										color="green" 
										dense
										@update:model-value = "gender_change()"
									>
									</q-radio>
								</div>
								<div class="col-1">
									<q-avatar :color='icm_sex' text-color="white" icon="pets" size="34px"></q-avatar>
								</div>
								<div class="col"></div>
							</div>
						</div>
	
						<!-- Chip Step 2-->
						<div class="step items-center text-left q-my-md q-mx-xl" v-if="step==2">
							Numero Microchip
							<q-input id="data.pet_tag" v-model="data.pet_tag" type="text"
								mask="###############"
								outlined 
								label-color="green"
								color="green"
								bg-color="white"
								hint="Inserire numero di microchip" 
								label=""
								:error="chip_error"
								error-message="Numero chip errato"
								v-on:update:model-value="isValidChip()"
							>
								<template v-slot:after>
									<q-avatar :color='icm_chip' text-color="white" icon="pets" size="34px"></q-avatar>
								</template>                    
							</q-input>
							<p></p>
							<b>Attenzione</b>: è importante che il numero di microchip sia corretto. Senza microchip non è possibile fare la card.
						</div>    
	
						<!-- Picture Step 3 -->
						<div class="step items-center text-left q-my-md q-mx-xl" v-if="step==3">
							Foto profilo
							<q-file
								name = "profile"
								color="white" 
								bg-color="white"
								outlined
								accept="image/*"
								v-model="img_file" 
								v-on:update:model-value="preload()"
							>
								<template v-slot:prepend>
									<q-icon name="photo_camera" color="green"></q-icon>
								</template>
								<template v-slot:after>
									<q-avatar :color='icm_pic' text-color="white" icon="pets" size="34px"></q-avatar>
								</template>   
							</q-file>
	
							<div class="row justify-center" v-if="is_cropping==this.CROP_START"	>
								<div v-show="data.profilePicture !== null" class="q-my-lg q-pa-lg avatar_cropper text-center" >
									<div>
										<vue-cropper
											ref="cropper"
											v-bind:src="data.profilePicture"
											:viewMode="2"
											aspectRatio=1
											:scalable=false
											:zoomable=true
											:background=false
											v-bind:minCropBoxWidth=minImgSize
											:autoCrop=true
											v-on:cropstart="crop_init"
										>
										</vue-cropper>
									</div>
									<q-btn 
										v-if="is_cropping==this.CROP_START" 
										color="green"                           
										text-color="white" 
										size="md"
										icon="done"
										class="q-ma-md" 
										label="Conferma la selezione fatta" 
										@click="crop_image()"
									></q-btn>
	
								</div>
							</div>
						</div>    
							
						<!-- Owner Step 4 -->
						<div class="step items-center text-left  q-my-md q-mx-xl" v-if="step==4">
							Nome e cognome Proprietario
							<q-input v-model="data.owner_name" type="text" 
								outlined 
								label-color="green"
								bg-color="white"
								color="green"
								hint="Inserisci nome e cognome" 
								label=""
								maxlength=60
								v-on:update:model-value="changed_owner_name()"
							>
								<template v-slot:after>
									<q-avatar :color='icm_owner' text-color="white" icon="pets" size="34px"></q-avatar>
								</template>
							</q-input>
							<div class="separator-blank q-my-md"></div>

							Email del Proprietario
							<q-input v-model="data.owner_email" type="text" 
								outlined 
								label-color="green"
								color="green"
								bg-color="white"
								hint="Email del proprietario"
								label=""
								disable
								maxlength=120
								:error="email_error"
								v-on:blur="isValidEmail(true)"
								v-on:update:model-value="isValidEmail(false)"
							>
								<template v-slot:after>
									<q-avatar :color='icm_email' text-color="white" icon="pets" size="34px"></q-avatar>
								</template>
							</q-input>
							<div class="separator-blank q-my-md"></div>
	
							Codice Fiscale del proprietario
							<q-input v-model="data.vatnumber" type="text" 
								outlined 
								label-color="green"
								color="green"
								bg-color="white"
								label=""
								maxlength=16
								:error="cf_error"
								v-on:update:model-value="changedVAT()"
								>
								<template v-slot:after>
									<q-avatar :color='icm_cf' text-color="white" icon="pets" size="34px"></q-avatar>
								</template>
								<template v-slot:label>
									<div class="hint">Necessario per l'emissione della fattura</div>
								</template>
							</q-input>
						</div>
	
						<!-- Privacy & Buy-->
						<div class="step items-center text-left  q-my-md q-mx-xl" v-if="step==5"> 
							<h4 v-if="step==5">Controlla e invia !</h4>
							Controlla la correttezza dei dati inseriti. Se necessario correggili poi procedi all'invio
	
							<div class="separator-blank q-my-lg"></div>
	
							<div class="text-center">
								<q-btn
									color="green" 
									text-color="white" 
									rounded
									icon = 'done_all'
									label = "Conferma e Invia"
									size='xl'
									@click="sendDataUpdate(true)"
								>										
								</q-btn>
								<!-- <div class="separator-blank q-my-md"></div>
								Oppure
								<div class="separator-blank q-my-md"></div>
								<q-btn
									color="orange" 
									text-color="white" 
									rounded
									icon = 'arrow_back'
									label = "Correggi Dati"
									size='md'
									@click="sendDataUpdate(true)"
								>
								</q-btn>-->
							</div>
						</div>

						<!-- Payment Error Step 10 -->
						<div class="step items-center q-my-md q-mx-xl text-center" v-if="step==10">
							<h3>Ci dispiace!</h3>
							Il tuo pagamento non è ancora andato a buon fine.<br>
							Ti invitiamo a controllare il pagamento e/o a riprovare più tardi
							Se hai bisogno non esitare a contattare il nostro supporto
						</div>
							
						<!-- NAV -->
						<div class="separator-blank q-my-xl "></div>

						<div v-if="step > 0 && step < 5">
							<div class="row items-center q-ma-md">
								<div class="col">
									<q-btn
										round
										size="lg"
										color="white" 
										text-color="green"
										dense 
										icon="arrow_back"
										@click="stepdown()"
										v-if="step>1"
									></q-btn>
								</div>
								<div class="col">
									Passaggio {{step}} / {{max_step}}
								</div>
								<div class="col">
									<q-btn
										round
										size="lg"
										:disable 	= "canStep[step]==false" 
										:color		= "canStepColor"
										text-color	= "white"
										dense 
										icon		= "arrow_forward" 
										v-if="step<max_step"
										@click="stepup()"
									></q-btn>
								</div>
							</div>
					
							<!-- Mandatory marker -->
							<div class="row q-mb-xl" v-if="canStep[step]==false">
								<div class="col text-left q-pa-md">
									<q-avatar color='red' text-color="white" icon="pets" size="34px"></q-avatar> Campi Obbligatori 
								</div>                
							</div>
						</div>
	
					</div>
				</div>

				<!-- Righ spacing Column -->
				<div class="col-xs-0 col-sm-0 col-md-0 col-lg-2 col-xl-4">
					&nbsp;
				</div>
			</div>
		</div>

		<!-- Chip Dialog -->
		<q-dialog 
			v-model="chip_dialog_show" 
			persistent
			transition-show = "slide-down"
			transition-hide = "slide-up"
			transition-duration = "800"
		>
			<q-card>
				<q-card-section>
					<div class="row items-center">
						<div class="col-12 text-center"><h3>Attenzione !</h3></div>
					</div>
					<div class="row items-center">
						<div class="col-12 text-center"><q-icon name="pets" color="green" size="100px"/></div>
					</div>

					<div class="row items-center">
						<div class="col-12 text-center">
							<h3>Sei assolutamente certo del<br>numero di chip inserito? </h3>
							<span class="text-center"><h3>{{ data.pet_tag }}</h3></span>
						</div>
					</div>
					<div class="separator-blank q-my-lg"></div>
					<div class="row items-center">
						<div class="col-12 text-center">
							<b>Non usare un numero inventato</b><br>o che non corrisponda a quello <b>del tuo pet</b>.<br>
							Un numero sbagliato potrebbe invalidare tutto il processo
						</div>
					</div>
				</q-card-section>

				<q-card-actions align="center">
					<q-btn flat icon="close" label="NO" color="red"   v-close-popup @click="reset_chip"></q-btn>
					<q-btn flat icon="check" label="SI" color="green" v-close-popup @click="confirm_chip"></q-btn>
				</q-card-actions>
			</q-card>
		</q-dialog>

		<!-- Mail Dialog -->
		<q-dialog 
			v-model="mail_dialog_show" 
			persistent
			transition-show = "slide-down"
			transition-hide = "slide-up"
			transition-duration = "800"
		>
			<q-card>
				<q-card-section>
					<div class="row items-center">
						<div class="col-12 text-center"><h3>Attenzione !</h3></div>
					</div>
					<div class="row items-center">
						<div class="col-12 text-center"><q-icon name="pets" color="green" size="100px"/></div>
					</div>

					<div class="row items-center">
						<div class="col-12 text-center">
							<h3>E' tuo questo pet? </h3>
						</div>
					</div>
					<div class="separator-blank q-my-lg"></div>
					<div class="row items-center">
						<div class="col-12 text-center">
							Questo pet risulta essere già registrato ma <br>
							<b>la mail del proprietario è diversa da quella inserita.</b><br>
							Usa l'indirizzo usato per la registrazione.
						</div>
					</div>
				</q-card-section>


				<q-card-actions align="center">
					<q-btn flat icon="check" label="OK" color="green" v-close-popup></q-btn>
				</q-card-actions>
			</q-card>
		</q-dialog>

		<!-- Select Pet Dialog -->
		<q-dialog
			v-model  = "pet_selector"
			persistent
		>
			<q-card style="width: 80%">
				<q-card-section>
					<h3>Per chi vuoi fare la MyPetCard?</h3>
					<div class="separator-blank q-my-lg"></div>
					<div class="row items-center">
						<div 
							v-for='(pet, idx) in pet_palette' :key='idx' 
							class="col-xs-6 col-sm-6 col-md-4 col-lg-4 col-xl-4 text-center"
						>

							<q-avatar v-if="pet.profilepic"
								size="90px" 
								v-on:click='onPetSelected(pet.fluid)'
							>
								<q-img
									ratio=1
									v-bind:src="pet.profilepic"
									spinner-color="white"
								>
								</q-img>
							</q-avatar>

							<q-avatar v-else
								size="90px" 
								v-on:click='onPetSelected(pet.fluid)'
								icon="pets"
								text-color="green"
								color="grey-4"
							>
							</q-avatar>

							<div v-on:click='onPetSelected(pet.fluid)'>
								<h4 class="ellipsis">{{ pet.name }}</h4>
							</div>
						</div>

					</div>
				</q-card-section>
			</q-card>
		</q-dialog> 

		<!-- Add Pet Dialog -->
		<q-dialog 
			v-model="add_pet_dialog" 
			persistent
			transition-show = "slide-down"
			transition-hide = "slide-up"
			transition-duration = "800"
		>
			<q-card>
				<q-card-section>
					<div class="row items-center">
						<div class="col-12 text-center"><h3>Attenzione !</h3></div>
					</div>
					<div class="row items-center">
						<div class="col-12 text-center"><q-icon name="pets" color="green" size="100px"/></div>
					</div>

					<div class="row items-center">
						<div class="col-12 text-center">
							<h3>Sei già registrato su <b>FaunaLife</b> ma<br> al tuo profilo non è associato
							nessun pet</h3>
							<span class="text-center"><h3>{{ data.pet_tag }}</h3></span>
						</div>
					</div>
					<div class="separator-blank q-my-lg"></div>
					<div class="row items-center">
						<div class="col-12">
							Ecco cosa devi fare:
							<ul>
								<li>Vai sull' <b>app FaunaLife</b></li>
								<li>Accedi con la tua email <b>{{ order.customer_email }}</b></li>
								<li>Clicca sull'icona <b><span class="material-icons text-bold">add_circle</span></b> per aggiungere il tuo pet</li>
								<li>Inserisci tutti i campi e la <b>foto</b></li>
								<li>Torna qui e procedi all'ordine della <b>MyPetCard</b></li>
							</ul>
						</div>
					</div>
				</q-card-section>

				<q-card-actions align="center">
					<q-btn 
						icon="arrow_forward" 
						label="OK" 
						color="green" 
						size="lg"
						v-close-popup @click="go_to_app()">
					</q-btn>
				</q-card-actions>
			</q-card>

		</q-dialog>

		<!-- Update Error Dialog -->
		<q-dialog
			position = bottom
			transition-show = "slide-up"
			transition-hide = "slide-down"
			transition-duration = "300"
			v-model  = "update_error"
			persistent = "false"
			auto-close
			allow-focus-outside
		>	
			<q-card style="width: 350px">
				<q-card-section class="row items-center no-wrap">
					<div>
						<q-icon color="red" name="error" size="34px"></q-icon>
					</div>
					<div class="text-center q-mx-md">
						Errore nell'aggiornamento dei dati: riprova
					</div>
				</q-card-section>
			</q-card>
		</q-dialog>

	</div>
</template>



<script>
import * as tools from '../common/tools'
import * as config from '../common/config'
import LogoImg from "../components/Logo"

import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'
import axios from 'axios'

export default {
	name: 'CardView',
	components: {
		VueCropper,
		LogoImg
	},

	props : {
		paymentId 		: {
			type		: String,	
			required	: true,
			default		: null
		},
	},

	data () {
		return {
			// Wizard & pagination
			step : 0,
			max_step : 5,
			todaystr : '',
			canStep : [false,false,false,false,false],
			window_blur : false,
			chip_dialog_show : false,
			mail_dialog_show : false,
			chip_confirmed : false,
			add_pet_dialog : false,
			update_error : false,
			email_error : false,
			chip_error : false,
			cf_error : true,
			
			// Collected Data
			data	: {},
			order 	: {},

			// Pets from the main app
			fluid	: '',
			pet_selector : false,
			pet_palette : {},

			// Image vars
			CROP_NONE : 0,
			CROP_START : 1,
			CROP_DONE : 2,
			img_file : null,
			img_hash : null,
			is_cropping : 0,
			minImgSize : config.AvatarSize,
			avatar_size : config.AvatarSize+'px',
			date_regex : config.Date_RegExp,
			email_regex : config.Email_RegExp,

			// Drop Down lists, selections and values
			species_list : '',
			breed_list :'',
			state_list : '',
			sel_species : '',
			sel_breed : '',
			short_breed : [' ', ' '],
			sel_build : '',
			opt_species : '',
			opt_breed : '',
			opt_breed_filtered : '',

			// Green/red paw colors
			icm_name	: 'red',
			icm_chip	: 'red',
			icm_pic		: 'red',
			icm_species	: 'red',
			icm_breed	: 'red',
			icm_dob		: 'red',
			icm_sex		: 'red',
			icm_owner	: 'red',
			icm_email	: 'red',
		}
	},

	computed : {
		qr_url : function () {
			if (this.data.pet_tag != '') {
				return config.ApiBaseUrl+'/util/qr/'+this.data.pet_tag
			} else {
				return config.ApiBaseUrl+'/util/qr/faunalife'
			}
		},

		blurred_on : function () {
			return this.chip_dialog_show || this.mail_dialog_show || this.window_blur || this.pet_selector
		},

		canStepColor : function () {
			return (this.canStep[this.step] == true )? 'green': 'grey'
		},

		icm_cf : function () {
			return (this.cf_error)?'red':'green'
		},

		icm_cq_gdpr : function () {
			return (this.data.cq_gdpr)?'green':'red'
		},

		icm_cq_blockchain : function () {
			return (this.data.cq_blockchain)?'green':'red'
		},

		icm_cq_newsletter : function () {
			return (this.data.cq_newsletter)?'green':'red'
		},
	},

	methods : {
		stepup : function () {
			this.step = (this.step == this.max_step)? this.max_step : this.step+1
			this.sendDataUpdate()
		},

		stepdown : function () {
			this.step = (this.step == 1)? 1 : this.step-1
			this.sendDataUpdate()
		},

		setWindowBlur :  function (val) {
			this.window_blur = val
		},

		// First page check ---------------------------------------------------

		first_page_step : function() {
			let canStep = (this.icm_name == 'green'	) &&
						(this.icm_dob == 'green') && 
						(this.icm_sex == 'green') && 
						(this.icm_species == 'green') &&
						(this.icm_breed == 'green') 
			this.canStep[1] = (canStep) ? true : false
		},

		changed_pet_name : function() {
			if (!this.data.pet_name) {
				this.data.pet_name = ''
			}
			// Can't have nonsence names
			this.data.pet_name = this.data.pet_name.trim()
			this.data.pet_name = this.data.pet_name.replace(/[^a-zA-Z ]/g, "")
			this.data.pet_name = this.data.pet_name ? this.data.pet_name : ''
			// Shorten if too long
			if (this.data.pet_name.length > 20) {
				this.data.pet_name = this.data.pet_name.slice(0,20) + '...'
			}
			this.icm_name = (this.data.pet_name.length >2) ? 'green' : 'red'
			this.first_page_step()
		},
		
		dob_update : function () {
			if (!this.data.birth_date) {
				this.icm_dob = 'red'
			} else {
				let d1 = tools.euDate2Date(this.data.birth_date)
				let dt = tools.euDate2Date(this.todaystr)
				this.icm_dob =  (this.date_regex.test(this.data.birth_date) && ( d1 <= dt ) ) ? 'green' : 'red'
			}
			this.first_page_step()
		},

		gender_change : function () {
			this.icm_sex = (['M','F'].includes(this.data.gender)) ? 'green' : 'red'
			this.first_page_step()
		},

		update_breed_list : async function () {
			if (!this.sel_species) {
				return
			}
			let species_id 	= this.species_list.filter(x => {return x.species_name == this.sel_species}).map(x=>{return x.id})[0]			
			this.data.species_id = species_id

			this.breed_list = await tools.apiGet('/util/lookup/breeds/?distinct=true&species_id='+species_id+'&cols=breed_id,IT as breed_name,EN,ORIGINAL')
			if (!this.breed_list.ok) {
				console.error(this.breed_list.message)
				return
			}
			delete this.breed_list.ok

			// remove empty lines and order by name 
			// some names might have a number in front to alter ordering
			this.breed_list = this.breed_list
				.filter(x => {return x.breed_name && x.breed_name != ''})
				.sort( (a,b)=> { 
					let o = a.breed_name.localeCompare(b.breed_name, 'it')  
					return (o > 0) ? 1 : ( (o<0)? -1 : 0 )
				})

			// Compose "complex" option list = "name (english, original)"
			this.opt_breed = this.breed_list.map(x=>{
				// Remove "numbers before name" ordering trick
				let opt = x.breed_name.replace(/^[0-9]+ /, '') 
				// Add Eng and Original between brackets and remove duplicates
				// Put into two sets to remove duplicates
				let opt_set = new Set(opt.split(', '))
				let en = x.EN.replace(/^[0-9]+ /, '')
				let or = x.ORIGINAL.replace(/^[0-9]+ /, '')
				let oth_set = new Set([en, or].join(', ').split(', '))
				oth_set.delete('')
				// Get element of eng+orig that are not in name
				let diff = [...oth_set].filter( x => { return !opt_set.has(x)})
				if (diff.length > 0) {
					opt += ' ('
					opt += (diff.length > 1) ? diff.join(', ') : diff[0]
					opt += ')'
				}
				return opt
			})
			if (this.opt_breed.length == 0) {
				this.opt_breed[0] = '(non definito)'
			}
			this.opt_breed_filtered = this.opt_breed
			this.sel_breed = ''

			// Mark this selection as good and ivalidate the other
			this.icm_species = 'green'
			this.icm_breed = 'red'
			this.update_short_breed()
		},

		update_short_breed : function () {
			if (this.sel_breed) {
				let short = this.sel_breed.replace(/ \(.*$/, '')
				let line=[' ',' ']
				
				let words = short.split(/[ ,]+/)
				let l = 0
				line[l] = words.shift()
				while (words.length) {
					let w = words.shift()
					if (line[l].length + w.length +1 <= 22) {
						line[l]+=' '+w
					} else {
						l+=1
						line[l] = w
					}
					short = line[0]+"<br>"+line[1]
				}
				this.short_breed = line

				// Get and store breed id
				let full = this.opt_breed.filter(x=>{return x.startsWith(this.sel_breed)})[0]
				this.data.breed_id = this.breed_list[this.opt_breed.indexOf(full)].breed_id

				// Good to go to next step
				this.icm_breed = 'green'
			} else {
				this.icm_breed = 'red'
			}
			this.first_page_step()
		},

		filterBreed : async function(val, update) {
			update(() => {
				const needle = val.toLocaleLowerCase()
				this.opt_breed_filtered = this.opt_breed.filter(v => v.toLocaleLowerCase().indexOf(needle) > -1)
			})
		},

		// Owner	-----------------------------------------------------------

		fourth_page_step : function() {
			let canStep = (this.icm_owner == 'green'	) &&
						(this.icm_email == 'green')
			
			// Allow for both steps (new/registered) to proceed
			this.canStep[4] = (canStep) ? true : false
			this.canStep[1] = (canStep) ? true : false
		},

		changed_owner_name : function() {
			if (!this.data.owner_name) {
				return false
			}
			// Can't have nonsence names
			this.data.owner_name = this.data.owner_name.trim()
			this.data.owner_name = this.data.owner_name.replace(/[^a-zA-Z ]/g, "")
			this.data.owner_name = tools.capitalizeWords(this.data.owner_name)
			// this.data.owner_name = this.data.owner_name ? this.data.owner_name : ''
			this.icm_owner = (this.data.owner_name.length > 4) ? 'green' : 'red'
			this.changedVAT()
			this.fourth_page_step()
		},

		isValidEmail : async function (blur) {
			// if (!this.data.owner_email) {
			// 	return false	
			// }
			// this.data.owner_email = this.data.owner_email.trim()
			// // Simple check on regex
			// let re = config.Email_RegExp
			// if (this.data.owner_email.match(re)) {
			// 	this.email_error = false
			// } else {
			// 	this.email_error = true
			// 	return false
			// }
			
			// let valid
			// if (tools.validAddress(this.fluid)) {
			// 	// Check if this is the right owner with the right email
			// 	let exists = await tools.apiUserExists(this.data.owner_fluid, this.data.owner_email)
			// 	valid = exists.ok
			// } else {
			// 	// New User : Check if belongs to disposable or wrong TLD
			// 	valid = await tools.apiGetEmailIsValid(this.data.owner_email)
			// }

			// if (valid) {
			// 	this.email_error = false
			// 	this.icm_email = 'green'
			// } else {
			// 	this.email_error = true
			// 	this.icm_email = 'red'
			// 	if (blur) {
			// 		this.mail_dialog_show = true
			// 		this.data.owner_email = ''
			// 	}
			// }
			console.error(blur)
			this.email_error = false
			this.icm_email = 'green'
			this.fourth_page_step()			
		},

		changedVAT : function () {
			if (this.data.vatnumber) {
				this.data.vatnumber = this.data.vatnumber.toUpperCase()
				this.cf_error = (!tools.isValidFiscalCode(this.data.vatnumber, this.data.owner_name)) 
			} else {
				this.cf_error = true
			}
			this.fourth_page_step()
		},


		// Chip	---------------------------------------------------------------

		isValidChip : async function(returning = false) {
			if (!this.data.pet_tag) {
				this.data.pet_tag = ''
			}
			// Check if supplied chip is syntacticaly valid and matches ISO for FDXB
			this.data.pet_tag = this.data.pet_tag.toUpperCase()
			let tag_start = this.data.pet_tag.slice(0,3)
			let iso3166 = (this.state_list.map(x=>{return x.iso3166.toString().padStart(3,'0')}).filter(x => {return x == tag_start}))[0]
			// 9XX numbers ar international or test
			if ( !iso3166 &&  tag_start[0]=='9') {
				iso3166 = tag_start
			}  
			
			if (!iso3166 || !tools.isValidChip(this.data.pet_tag,iso3166)) {
				this.chip_error = true
				this.icm_chip = 'red'
				this.chip_confirmed = false
				this.canStep[2] = false
				return false
			}

			// Check if chip is already used (unless it's been already checked)
			if (!returning) {
				let r = await tools.apiGet('/fluid/from/'+this.data.pet_tag+'/?new=true')
				if (r.ok==false) {
					alert("Questo numero di chip è già stato registrato")
					this.chip_error = true
					this.icm_chip = 'red'
					this.chip_confirmed = false
					this.canStep[2] = false
					return false    
				} 
			}
			
			// All good man !
			this.chip_error = false
			this.icm_chip = 'green'
			this.canStep[2] = true

			// Ask user to confirm chip value
			if (!this.chip_confirmed) {
				this.chip_dialog_show = true
			}
			return true
		},

		reset_chip: function () {
			this.chip_error = true
			this.data.pet_tag = ''
		},

		confirm_chip: function () {
			this.canStep[this.step] = true
			this.chip_confirmed = true
			this.sendDataUpdate(false)
			this.step +=1
		},

		// Avatar	-----------------------------------------------------------

		hash_from_blob : function(blob) {
			let fr = new FileReader()
			fr.onload =  () => {
				this.img_hash = '0x'+tools.sha256sum(fr.result)
			}
			fr.readAsArrayBuffer(blob)
		},

		preload: function() {            
			let fr = new FileReader()
			this.is_cropping = this.CROP_START
			if (this.img_file.type.split('/')[0] == 'image') {
				// It's an Image ! -> crop etc
				// Remember to always use arrow functions for hevent handlers!
				fr.onload =  () => {
					// Load in new image for resizing
					let image = new Image()
					// Remember to always use arrow functions for event handlers!
					image.onload=() => {
						// Reduce Image size since some browsers can't handle huge images
						let canvas=document.createElement("canvas")
						let context=canvas.getContext("2d")
						canvas.width=tools.getReducedSize(image.width,image.height)[0]
						canvas.height=tools.getReducedSize(image.width,image.height)[1]
						context.drawImage(image,0,0,image.width,image.height,0,0,canvas.width,canvas.height)
						// Put resized image in cropper
						this.data.profilePicture = canvas.toDataURL()
						// Get hash
						// let data = tools.dataURItoBlob(this.data.profilePicture)  
						// this.hash_from_blob(data)
						this.$refs.cropper.replace(this.data.profilePicture);
						
						// Force cropping
						// this.$refs.cropper.initCrop()
						this.is_cropping = this.CROP_START
					}
					image.src = fr.result
				}
				fr.readAsDataURL(this.img_file)
			} else {
				alert("E' possibile solo caricare immagini")
			}
		},

		crop_init : async function () {
			this.img_hash = null
			this.$refs.cropper.initCrop()
			this.is_cropping = this.CROP_START
		},

		crop_image : async function () {
			// Replace Image with cropped part
			this.data.profilePicture = this.$refs.cropper
				.getCroppedCanvas()
				.toDataURL()
			// Get Image to config.AvatarSize x config.AvatarSize
			let image = new Image()
			image.onload=() => {
				let canvas=document.createElement("canvas")
				let context=canvas.getContext("2d")
				canvas.width=config.AvatarSize
				canvas.height=config.AvatarSize
				context.drawImage(image,0,0,image.width,image.height,0,0,canvas.width,canvas.height)
				// Put resized image in cropper
				this.data.profilePicture = canvas.toDataURL()
				let data = tools.dataURItoBlob(this.data.profilePicture)  
				this.hash_from_blob(data)
				this.$refs.cropper.replace(this.data.profilePicture)
				this.is_cropping = this.CROP_DONE
				this.$refs.cropper.clear()
				this.canStep[3] = true
				this.icm_pic = 'green'
			}
			image.src = this.data.profilePicture
		},

		// Page ---------------------------------------------------------------

		/**
		 * Should we have a specific pet already, get the data from the main
		 * app and use it in the page
		 * @param {} pet 
		 */
		importData : async function (pet) {
			// Get data from blockchain
			this.data.pet_name		= pet.rawattributes.name
			this.data.pet_tag		= pet.rawattributes.idtag
			this.data.birth_date		= tools.date2EUDate(pet.rawattributes.birth)
			this.data.gender		= pet.rawattributes.gender
			this.data.cq_gdpr		= true
			this.data.cq_blockchain = true
			this.data.cq_newsletter = true
			this.sel_species		= this.species_list.filter(x => {return x.id == parseInt(pet.rawcodes.species)}).map(x=>{return x.species_name})[0]
			await this.update_breed_list()
			this.sel_breed			= this.breed_list.filter(x=>{return x.breed_id == parseInt(pet.rawcodes.breed)}).map(x=>{return x.breed_name})[0]
			this.sel_breed 			= this.sel_breed.replace(/^[0-9]+ /, '') 
			this.update_short_breed()

			// Store owner fluid for later check
			this.data.owner_fluid	= pet.owners[pet.owners.length-1]
			
			// Pet Image
			this.data.profilePicture	= pet.profilepicurl[pet.profilepicurl.length-1]
			this.is_cropping			=this.CROP_DONE

			// Check data and confirm in interface
			this.gender_change()
			this.dob_update()
			this.changed_pet_name()
		},

		/**
		 * Initialize the page
		 * get form data from the BE
		 */
		initialize : async function () {
			// Set Dates
			const yourDate = new Date()
			this.todaystr = yourDate.toLocaleDateString(config.Date_Locale, config.Date_Options)
			this.max_year_month = yourDate.getFullYear()+'/'+yourDate.getMonth()

			// Get species values
			this.species_list = await tools.apiGet('/util/lookup/species?distinct=true&cols=id,IT as species_name')
			if (!this.species_list.ok) {
				console.error(this.species_list.message)
				return
			}
			this.opt_species = this.species_list.filter(x => {return x.id != '0x00'}).map(x => {return x.species_name} )

			// Get geo values (for ISO Chip Check)
			this.state_list =  await tools.apiGet('/util/lookup/countries/?distinct=true')
			if (!this.state_list.ok) {
				console.error(this.state_list.message)
				return
			}

			// Do we have a query string?
			this.data.querystring	= JSON.stringify(this.$route.query)

			// Is this a new user or it comes from the app ??
			// Check that fluid is valid (regex)
			if (tools.validAddress(this.fluid)) {
				// Check that fluid exists in app
				let pet = await tools.apiGetPet(this.fluid)
				if (pet!=null) {
					// Add fluid to data
					this.data.fluid = this.fluid
					// console.error("PET: ", pet)
					// Get data from blockchain
					await this.importData(pet)									
				} else {
					console.error("Non existing Fluid")
				}
			} 

			// See if there was a previous card in progress
			let lsdata = {}
			if (this.order.data ) {
				// Try to get data from the previoulsy stored one
				lsdata = {...this.order.data.pet, ...this.order.data.owner}
				lsdata.querystring = this.order.data.querystring
				delete this.order.data
			}
			lsdata.owner_email = this.order.customer_email
			lsdata.owner_name  = this.order.customer_name
			
			// Merge stored data and API data, API has precedence
			this.data = {...this.data, ...this.order, ...lsdata }
						
			// update interface
			if (this.data.pet_name) {
				this.changed_pet_name()
			}

			if (this.data.species_id) {
				this.sel_species		= this.species_list.filter(x => {return x.id == parseInt(this.data.species_id)}).map(x=>{return x.species_name})[0]
				await this.update_breed_list()
				if (this.data.breed_id) {
					this.sel_breed			= this.breed_list.filter(x=>{return x.breed_id == parseInt(this.data.breed_id)}).map(x=>{return x.breed_name})[0]
					this.sel_breed 			= this.sel_breed.replace(/^[0-9]+ /, '')
					this.update_short_breed()
				}
			}
			if (this.data.birth_date) {
				this.dob_update()
			}
			if (this.data.gender) {
				this.gender_change()
			}
			if (this.data.pet_tag) {
				// Assume the previously given chip is correct
				this.chip_confirmed 	= true
				this.isValidChip({returning : true})
			}
			if (this.data.profilePicture) {
				this.is_cropping		=this.CROP_DONE
				this.canStep[3] 		= true
				this.icm_pic			= 'green'
			}
			if (this.data.owner_name) {
				this.changed_owner_name()
			}
			if (this.data.owner_email) {
				this.isValidEmail()
			}

			// Set privacy cecks
			this.data.cq_blockchain	= this.data.cq_blockchain	|| true
			this.data.cq_gdpr		= this.data.cq_gdpr			|| true
			this.data.cq_newsletter	= this.data.cq_newsletter 	|| true

			// console.error("Data: ", this.data)
			
		},

		/* Collect data in "unpacked" in a flat dictionary for ease of use
		 * in the page. Data gets packed in a way that is more convenient for 
		 * backend database. 
		 * All pet and owner data are into an object, and the data that will 
		 * later be needed for other operations are left in the flat part
		 */
		packData : function() {
			function move(struct, field, sub) {
				struct[sub][field] = struct[field]
				delete struct[field]
			}

			let packed = JSON.parse(JSON.stringify(this.data))
			
			// Separate Owners fields
			packed.owner = {}
			move(packed, 'cq_blockchain', 'owner')
			move(packed, 'cq_gdpr', 'owner')
			move(packed, 'cq_newsletter', 'owner')
			move(packed, 'owner_fluid', 'owner')
			move(packed, 'owner_name', 'owner')
			move(packed, 'owner_email', 'owner')
			move(packed, 'vatnumber', 'owner')
			
			// Separate Pet Data
			packed.pet	= {}
			move(packed, 'birth_date', 'pet')
			move(packed, 'breed_id', 'pet')
			move(packed, 'gender', 'pet')
			move(packed, 'pet_name', 'pet')
			move(packed, 'pet_tag', 'pet')
			move(packed, 'species_id', 'pet')
			move(packed, 'profilePicture', 'pet')
			
			return packed
		},

		/**
		 * Send all collected data to BE so to ease further later
		 * completion steps
		 * @param {*} final if it's the last update -> order completed 
		 */
		sendDataUpdate : async function (final = false) {
			// Check if there was a change, otherwise save the effort
			let packed = this.packData()			
			if (JSON.stringify(this.order.data) === JSON.stringify(packed) && !final) {
				return
			} 
			if (final) {
				this.order.status = "complete"
			} 				
			
			this.order.data = JSON.stringify(packed)

			// Update order data on the backend
			let endpoint = config.ApiShopUrl+'/buy/update'
			try {
				let r = await axios.post(endpoint, this.order, {
										withCredentials: true,
										// validateStatus: status => {return true;}    		//TRICK: consider all responses valid...
										validateStatus: status => {return status==status}	// Because Vue complains about unused vars...
									})

				if (r.status != 200) {
					// Some error Happened
					console.error(JSON.stringify(r,null,4))
					if (final) {
						this.update_error = true
					} else {
						// Raise hell here
					}
				} else {
					if (final) {
						// Go to final Thankyou page
						this.$router.push("/goodbye/"+this.order.customer_email)
					}
				}
			} catch (e) {
				this.update_error = true
			}
		},

		/**
		 * Check the BE & Processor for the payment status
		 */
		confirmPayment : async function() {
			let payment_ok = false
			if (this.paymentId && typeof this.paymentId === 'string' && this.paymentId !== '' ) {
				this.order = await tools.apiShopConfirm('paymentId='+this.paymentId)
				if (this.order.ok) {
					payment_ok = true
					switch(this.order.status_code) {
						case 900:
							// Payed
							this.order = this.order.order_data
							this.order.id = this.order.order_id
							break
						case 999:
							// Already completed
							console.error("ORD ", this.order)
							this.order = this.order.order_data
							this.$router.push("/goodbye/"+this.order.customer_email)
							break
						default:
							payment_ok = false
							console.error("invalid payment id ", this.paymentId)
							this.step = 10
							break
					}
				}
			} else {
				this.$router.push("/buy")
			}
			return payment_ok
		},


		/**
		 * Gets the use email from the order
		 * If the email is a FaunaLife registered user show him his pets to choose among
		 * this way a user selects the pet and has no need to go through the card wizard
		 */
		userShortcut : async function() {
			let go_on = false
			this.pet_palette = []
			let user = await tools.apiUserExists(null, this.order.customer_email)
			if (!user.ok || !user.exists) {
				go_on = true
				return go_on
			}
			user = await tools.apiGetUser(user.fluid)
			if(user.ok && user.attributes && user.attributes.owns && Array.isArray(user.attributes.owns) && user.attributes.owns.length > 0) {
				// User has pets !!
				let pets=user.attributes.owns
				let proms=[]
				for (let fluid of pets) {
					proms.push(tools.apiGetPet(fluid))
				}
				let resps = await Promise.allSettled(proms)
				for (let resp of resps) {
					if (resp.status != 'fulfilled') {
						console.error(resp.value.message)   
					} else {
						if (resp.value && resp.value.ok) {
							let pet = resp.value
							// Is current owner ?
							if (pet.owners && Array.isArray(pet.owners) && 
								pet.owners.length > 0 && pet.owners[pet.owners.length-1] === user.user) {
								// Skip if duplicate
								let dup = this.pet_palette.filter(x=> {return x.fluid === pet.fluid})
								if (dup.length != []) {
									continue
								}
								// Skip Stelline
								if (pet.rawattributes.death) {
									continue
								}
								// Skip pets without profile picture
								if (!pet.profilepicurl || !Array.isArray(pet.profilepicurl) ||pet.profilepicurl.length==0 ) {
									continue
								} 
								// Add pet to palette
								let card = {}
								card.name = pet.rawattributes.name
								card.fluid = pet.fluid
								card.profilepic  = pet.profilepicurl[pet.profilepicurl.length-1]
								this.pet_palette.push(card)
							} else {
								continue
							}
						}
					}
				}

				// How many registered pets does the user have ??
				switch (this.pet_palette.length) {
					case 0 :	// NO PETS ?? Go add one
						this.pet_selector = false
						this.add_pet_dialog = true
						go_on = false
						break
					case 1 :	// One pet -> go
						this.pet_selector = false
						this.onPetSelected( this.pet_palette[0].fluid )
						go_on = true
						break
					default :	// More than 1
						this.pet_selector = true
						go_on = false
						break
				}
			} else {
				go_on = true
			}
			return go_on
		},

		/**
		 * Handles pet selection from palette
		 * @param {*} fluid 
		 */
		onPetSelected : async function (fluid) {
			this.fluid = fluid
			this.order.fluid = fluid
			this.pet_selector = false
			await this.initialize()
			// Enable free navigation
			this.canStep = [true,true,true,true,true]
			// Go to user data step
			this.step = 4
		},

		go_to_app : function () {
			let url=config.AppBaseUrl + "/?flmail="+this.order.customer_email
			window.open(url)
		}

	},


	// Lifecycle Hooks
	async mounted() {
		window.scrollTo(0,0)
		// Check that the payment is ok
		if (await this.confirmPayment()) {
			// Check if it's a Main App user
			if (await this.userShortcut()) {
				await this.initialize()
				this.step = tools.validAddress(this.fluid) ? 4 : 1
			}
		} else {
			this.$router.push("/buy")
		}
	},

}
</script>

<style>

	.hint {
		color : var(--fl-gray-2);
		font-size: 15px;
	}

	.blurred {
		filter: blur(5px);
	}

	.scalable-wrapper {
		padding: 5px;
		/* resize: both; */
		/* position: fixed; */
		position: relative;
		/* background: #666; */
		height: 300px;
		z-index: 10; 
		/* position: fixed;  */
		/* position: sticky; 
		position: -webkit-sticky; */
		/* right: 0;  */
		top: 0px;
	}

	.card_background {
		background-image: url("@/assets/Front_BLANK_Fake3.png");
		background-repeat: no-repeat;
		background-position: center;
		background-size: contain;

		width: 400px;
		height: 300px;
		padding: 10px;
		text-align: left;
		position: relative;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		transform-origin: center center;
	}

	.card_font {
		outline: none!important;
		white-space: nowrap!important;
		word-break: keep-all;
		text-transform: capitalize;
		z-index: 1;
		line-height: 12px!important;;

		font-family: "Arial Black", Gadget, sans-serif;
		font-size: 13px;
		letter-spacing: 1px;
		word-spacing: 1px;
		color: #000000;
		font-weight: 700;
		text-decoration: none;
		font-style: normal;
		font-variant: normal;
	}

	.card_data {
		position : absolute;
		left: 85px;
		top: 59px;
	}

	.datapos_name {
		position : absolute;
		left: 10px;
		top: 8px;
	}

	/* 
		Note to self: 
		this part hereunder is some crappy CSS woodoo
		making the image smaller... makes it disappear
		Dunno why
	*/
	.datapos_id_picture_container {
		position : absolute;
		display : block;
		left: 274px;
		top: 45px;
		height: 100px;
		width: 100px; 
	}

	.datapos_id_picture {
		border-radius: 50%;
		height: 100%;
		width: 100%; 
	}

	.datapos_qr_code {
		position: absolute;
		height: 83px;
		width: 83px;
		left : 199px;
		top : 108px;
	}

	.datapos_species {
		position : absolute;
		left: 10px;
		top : 92px;
	}

	.datapos_breed {
		position : absolute;
		left: 10px;
		top : 119px;
	}
	.datapos_breed2 {
		position : absolute;
		left: 10px;
		top : 134px;
	}

	.datapos_dob {
		position : absolute;
		left: 10px;
		top : 38px;
	}

	.datapos_sex {
		position : absolute;
		left: 10px;
		top : 64px;

	}

	.datapos_owner {
		position: absolute;
		left : 10px;
		top : 178px;
	}

	/* 
		those css values apply only to the .avatar_cropper div in this page
		used to give the circle selection to the cropper
	*/
	.avatar_cropper .cropper-view-box {
		box-shadow: 0 0 0 1px #39f;
		border-radius: 50%;
		outline: 0;
	}
	.avatar_cropper .cropper-face {
		background-color:inherit !important;
	}
	.avatar_cropper .cropper-view-box {
		outline:inherit !important;
	}

</style>