<template>
	<div class="container">
		<div class="row">
			<div class="col-12">
				<div class="separator-blank q-my-lg"></div>
				<h2>Grazie! <q-icon name="pets"></q-icon></h2>
				<h3>per aver acquistato MyPetCard</h3>
			</div>
		</div>
		<div class="separator-blank q-my-md"></div>
		<div class="row">
			<div class="col-xs-1 col-sm-2 col-md-3 col-lg-3 col-xl-3"></div>

			<!-- Compilation Completed -->
			<div class="col-xs-10 col-sm-8 col-md-6 col-lg-6 col-xl-6 text-justify">
                <p>Il tuo ordine è stato ricevuto e hai compilato i dati.</p>
                <p>A breve la tua card verrà stampata e spedita.</p>
                <p>Verrai avvisato via email della avvenuta spedizione appena possibile.</p>
                <p>Se hai altre necessità <span @click=" emailSupport()"><b>puoi rivolgerti al nostro supporto</b></span></p>
				<div class="separator-blank q-my-xl"></div>
				<h3>Cosa puoi fare ora ?</h3>
				<p><b>Accedi al tuo profilo sulla App di FaunaLife</b> e completa i dati del tuo pet
				inserendo note, e documenti.</p>
				<p>Se hai altri pet potrai aggiungerli sulla App e acquistare la loro <b>MyPetCard</b>
				direttamente dalla app.</p>
				<div class="text-center">
					<q-btn 
						icon="arrow_forward" 
						label="Vai  alla  App" 
						color="green" 
						size="lg"
						v-close-popup @click="go_to_app()">
					</q-btn>
				</div>
			</div>


			<div class="col-xs-1 col-sm-2 col-md-3 col-lg-3 col-xl-3"></div>
		</div>
		<div class="row bg-dogGirl" style="height: 550px;">
			&nbsp;
		</div>


	</div>
</template>



<script>
// import * as tools from '../common/tools'
import * as config from '../common/config'

	export default {
		name: ' ByeView',
		components: {
		},

		props : {
			customer_email 		: {
				type		: String,	
				required	: true,
				default		: null
			},
		},

		data () {
		},

		computed : {
		},

		methods : {
			go_to_app : function () {
				let url=config.AppBaseUrl + "/?flmail="+this.customer_email
				window.open(url)
			},

			emailSupport : function () {
				let mail_link = 'mailto:support@fauna.life?subject=Richiesta%20supporto'
				window.open(mail_link, null);
			}
		},

		// Lifecycle Hooks
		mounted() {
			window.scrollTo(0,0)
		},
	}
</script>


<style>
</style>