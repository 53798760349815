module.exports = {
    AppVersion : process.env.VUE_APP_VERSION,                   // Gets it from last Git commit
    ApiBaseUrl : process.env.VUE_APP_API_BASE,                  // Main FaunaLife app api https://app.fauna.life/api
    ApiShopUrl : process.env.VUE_APP_API_SHOP,                  // Shop API
    // TrxBaseUrl : process.env.VUE_APP_EXPLORER,               // Quadrans Explorer
    AppBaseUrl : process.env.VUE_APP_BASE_URL,                  // URL of the base FaunaLife App https://app.fauna.life
   
    AvatarSize : '400',             //size in pixel for application page
    AvatarPrintSize : '130',        //size in pixel for document print
    AvatarSmallSize : '90',

    JWT_CookieName : process.env.VUE_APP_JWT_TOKEN,
    MaxImageSide : 600,

    Date_Locale : 'it-IT',
    Date_Options : { year: 'numeric', month: '2-digit', day: '2-digit' },

    MapsApiKey  :   process.env.VUE_APP_MAPS_KEY,

    Pagination_perPage : 8,

    // Regular expressions
    Fluid_RegExp    : /^0x[a-fA-F0-9]{40}$/,
    Email_RegExp    : /^([a-zA-Z0-9_\-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/,
    // Email_RegExp    : /^[a-z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-z0-9-]+(?:\.[a-z0-9-]+)*$/i,
    Date_RegExp     : /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/,
    Fiscal_RegExp   : /^[A-Z]{6}[0-9]{2}[ABCDEHLMPRST][0-9]{2}[A-Z][0-9]{3}[A-Z]$/,

    // Known valid chip formats
    Tag_RegExp : [
        "^[0-9]{15}$",      // ISO FDXB
        // "^[0-9A-Z]{10}$",   // ISO FDXA
        // "^[0-9]{9}$",
        // "^[0-9]{10}$",
    ],
}