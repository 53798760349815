<template>
	<div class="container">
		<div class="row">
			<div class="col-12">
				<div class="separator-blank q-my-lg"></div>
				<h2>Grazie! <q-icon name="pets"></q-icon></h2>
				<h3>per aver acquistato MyPetCard!</h3>
			</div>
		</div>
		<div class="separator-blank q-my-md"></div>
		<div class="row">
			<div class="col-xs-1 col-sm-2 col-md-3 col-lg-3 col-xl-3"></div>

			<div class="col-xs-10 col-sm-8 col-md-6 col-lg-6 col-xl-6 text-center" v-if="payment_status==0">
				<q-spinner-ios
					color="green"
					size="6em"
					:thickness="9"
				></q-spinner-ios>
			</div>

			<!-- Payment confirmed -->
			<div class="col-xs-10 col-sm-8 col-md-6 col-lg-6 col-xl-6 text-left" v-if="payment_status==900">
				Il tuo sostegno ci aiuta a impegnarci ancora di più per realizzare la nostra missione di creare un Registro animale internazionale e proteggere sempre meglio i nostri amati pet!
				<!-- <div class="separator-blank q-my-md"></div>
				<div class="addressbox">
					<span v-html="shippingAddress"></span>
				</div> -->
				<div class="separator-blank q-my-lg"></div> 
				Ti abbiamo inviato una mail all'indirizzo <b>{{ order_data.customer_email }}</b> con tutte le istruzioni per ricevere la MyPetCard del tuo amico a quattro zampe (controlla anche nello spam!)
				<div class="separator-blank q-my-lg"></div>
				Una volta compilata la scheda con le informazioni, la card verrà spedita all'indirizzo che ci hai indicato.
				<div class="separator-blank q-my-lg"></div>
				Tieni a portata di mano il <b>numero di microchip</b> del tuo pet, lo puoi trovare sul libretto sanitario, è indispensabile per creare la card!
				<div class="separator-blank q-my-xl"></div>
				<div class="text-center">
					<q-btn
						label="Ho già il numero di microchip, posso compilare adesso la scheda!"
						color="green"
						text-color="white"
						rounded=""
						size ="lg"
						padding = "15px"
						@click="goToCard()"
					>
					</q-btn>
				</div>
			</div>

			<!-- Payment delayed or failed -->
			<div class="col-xs-10 col-sm-8 col-md-6 col-lg-6 col-xl-6 text-left" v-if="payment_status==666">
				Il tuo pagamento <b>non è andato a buon fine o non è ancora stato processato</b>.<br>
				Controlla il pagamento oppure riprova tra qualche minuto.
				<div class="separator-blank q-my-xl"></div> 
				Se questa condizione persiste <span @click=" emailSupport()"><b>contatta il nostro supporto</b></span>.
				<div class="separator-blank q-my-xl"></div>
				Potrai procedere con la creazione della tua <b>MyPetCard</b> appena il pagamento sarà andato a buon fine
			</div>
			<div class="col-xs-1 col-sm-2 col-md-3 col-lg-3 col-xl-3"></div>
		</div>
		<div class="row bg-dogGirl" style="height: 550px;">
			&nbsp;
		</div>


	</div>
</template>



<script>
import * as tools from '../common/tools'
// import * as config from '../common/config'

export default {
	name: 'SuccessView',
	components: {
	},

	data () {
		return {
			query 			: this.$route.query,
			order_data		: {},
			payment_status	: 0,
		}
	},

	computed : {
		shippingAddress : function () {
			if (!this.order_data || !this.order_data.shipping_address) {
				return ''
			}
			let sa = JSON.parse(this.order_data.shipping_address)
			let address=[]
			address.push(this.order_data.customer_name)
			address.push(sa.line1 || null)
			address.push(sa.line2 || null)
			address.push((sa.postal_code || null) +' '+ (sa.city || null))
			address.push((sa.state || null) +' - '+ (sa.country || sa.country_code || null))
			address = address.filter(x=>{return x!=null})
			return address.join("<br>")
		},

	},

	methods : {
		initialize : async function () {

			// Give the backend & Payment processor some time...
			function sleep(ms) {
				return new Promise(resolve => setTimeout(resolve, ms));
			}
			await sleep(3000)

			// Warn backend that payment ended sending back the whole query string received from processor
			const qs = new URLSearchParams(this.query).toString()
			let succeded = await tools.apiShopConfirm(qs)
			console.error("status: ", succeded)
			
			if (succeded.ok) {
				console.error("status code: ", succeded.status_code)
				this.payment_status = succeded.status_code
				switch (parseInt(succeded.status_code)) {
					
					case 900:	// Payed
						this.order_data = succeded.order_data
						break
					case 999:	// Completed
						this.$router.push("/goodbye")
						break
					case 908:	// Wrong Vat number / Fiscal code
						this.order_data = succeded.order_data
						break

					// case 902:	// Query error
					// 	/* falls through */
					// case 903:	// Non existing payment 
					// 	break
					// case 904:	// Could not fetch payment data : retry later
					// 	/* falls through */
					// case 905:	// Payment not done / not completed : retry later
					// 	break
					// case 906:	// Wrong amount
					// 	break
					// case 907:	// Unknown processor
					// 	break

					default :
						// Didn't go as expected
						this.payment_status = 666
						break
				}
			} else {
				this.payment_status = 666
			}
		},

		goToCard : async function () {
			this.$router.push("/card/"+this.order_data.order_id)
		},

		emailSupport : function () {
			const qs = '?' + new URLSearchParams(this.query).toString();
			let mail_link = 'mailto:support@fauna.life?subject=Controllo%20pagamento&body='
			mail_link += encodeURIComponent('La presente per il controllo del seguente pagamento:\n'+qs)
			window.open(mail_link, null);
		}
	},

	// Lifecycle Hooks
	mounted() {
		window.scrollTo(0,0)
		this.initialize()
	},
}
</script>


<style>
	.addressbox {
		border: 2px solid var(--fl-gray-3);
		padding: 10px 40px;
		-webkit-border-radius: 15px;
		border-radius: 15px;
		background: var(--fl-gray-4);
	}
</style>